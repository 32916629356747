import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../utils/LanguageContext";
import russian from "../../../images/russian.png";
import british from "../../../images/britishNew.png";
import arrow from "../../../images/arrow.png";
import womanGender from "../../../images/womanGender.png";
import manGender from "../../../images/mangender.png";
import visible from "../../../images/visible.png";
import invisible from "../../../images/unvisible.svg";
import backButton1 from "../../../images/backButton1.svg";
import useUserGet from "../../../hooks/useUserGet";
import useUpdateUserData from "../../../hooks/useUpdateUserData";
import useLanguageUpdate from "../../../hooks/useLanguageUpdate";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import useFetchAvatars from "../../../hooks/useFetchAvatars";

const SettingsModal = ({ onClose, tgId, setGenderChanged }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language, changeLanguage } = useLanguage();
  const [gender, setGender] = useState(null);
  const [visibility, setVisibility] = useState(null);
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const { avatars, loading, error } = useFetchAvatars();

  const {
    user: fetchedUser,
    loading: userLoading,
    error: userError,
  } = useUserGet(tgId);
  const {
    updateUserData,
    loading: updatingLoading,
    error: updatingError,
  } = useUpdateUserData();
  const {
    updateLanguage,
    loading: languageLoading,
    error: languageError,
  } = useLanguageUpdate();

  useEffect(() => {
    if (fetchedUser) {
      setGender(fetchedUser.gender);
    }
  }, [fetchedUser]);

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const handleGenderChange = async (newGender) => {
    impactOccurred("soft");
    setGender(newGender);

    await updateUserData(tgId, newGender);
    setGenderChanged(newGender);

    localStorage.setItem("genderUser", newGender);

    let avatarId;
    if (newGender === false) {
      avatarId = 1;
    } else if (newGender === true) {
      avatarId = 3;
    } else {
      console.error("Invalid gender:", newGender);
      return;
    }

    const avatarsList = newGender === false ? avatars.women : avatars.men;
    const avatar = avatarsList.find((av) => av.id === avatarId);

    if (avatar) {
      const fullAvatarUrl = `https://tg-back.itc-hub.ru${avatar.image}`;
      localStorage.setItem("avatar", fullAvatarUrl);
    } else {
      console.error("Avatar not found for ID:", avatarId);
    }
  };

  const handleLanguageChange = async (newLanguage) => {
    impactOccurred("soft");
    const languageValue = newLanguage === "russian" ? 0 : 1;
    changeLanguage(newLanguage === "russian" ? "ru" : "en");
    await updateLanguage(tgId, languageValue);
  };

  const changeVisibility = (isVisible) => {
    impactOccurred("soft");
    setVisibility(isVisible);
  };

  const handlePolicyClick = (e) => {
    impactOccurred("soft");
    e.preventDefault();
    const policyUrl =
      language === "ru"
        ? "https://telegra.ph/Politika-konfidencialnosti-08-03-7"
        : "https://telegra.ph/Privacy-Policy-08-03-66";
    window.open(policyUrl, '_blank'); // Открыть ссылку в новой вкладке
  };
  
  return (
    <div className="modal__overlay_settings" onClick={onClose}>
      <div className="modal__content_settings" onClick={handleClick}>
        <div className="modal__block_settings">
          <div className="settingsModal__box">
            <div className="button_w-header">
            <button className="backButton_settings" onClick={onClose}>
              <img
                className="backButton_settings_icon"
                src={backButton1}
                alt="Back"
              />
            </button>
            <h3 className="settingsModal__h">{t("SETTINGS")}</h3>
            </div>
            <div className="setting__item">
              <p className="setting__item_p">{t("Choose a language")}</p>
              <div className="setting__item_buttons">
                <button
                  className="setting__item_button"
                  style={{ opacity: language === "ru" ? 1 : 0.4 }}
                  onClick={(e) => {
                    handleLanguageChange("russian");
                    handleClick(e);
                  }}
                >
                  <img
                    className="setting__item_icon"
                    src={russian}
                    alt="Russian"
                  />
                </button>
                <button
                  className="setting__item_button"
                  style={{ opacity: language === "en" ? 1 : 0.4 }}
                  onClick={(e) => {
                    handleLanguageChange("british");
                    handleClick(e);
                  }}
                >
                  <img
                    className="setting__item_icon"
                    src={british}
                    alt="British"
                  />
                </button>
              </div>
            </div>

            <div className="setting__item">
              <p className="setting__item_p">{t("Choose a gender")}</p>
              <div className="setting__item_buttons">
                <button
                  className="setting__item_button"
                  style={{ opacity: gender === true ? 1 : 0.4 }}
                  onClick={(e) => {
                    handleGenderChange(true);
                    handleClick(e);
                  }}
                >
                  <img
                    className="setting__item_icon"
                    style={{ marginBottom: "-4px" }}
                    src={manGender}
                    alt="Man"
                  />
                </button>
                <button
                  className="setting__item_button"
                  style={{ opacity: gender === false ? 1 : 0.4 }}
                  onClick={(e) => {
                    handleGenderChange(false);
                    handleClick(e);
                  }}
                >
                  <img
                    className="setting__item_icon"
                    style={{ marginBottom: "-5px" }}
                    src={womanGender}
                    alt="Woman"
                  />
                </button>
              </div>
            </div>


            <div className="setting__item">
              <p className="setting__item_p">{t("Hide data")}</p>
              <div className="setting__item_buttons">
                <button
                  className="setting__item_button"
                  style={{ border: visibility === true ? '2px solid #b5f231' : 'none' }}
                  onClick={(e) => {
                    changeVisibility(true);
                    handleClick(e);
                  }}
                >
                  <img
                    className="setting__item_icon"
                    style={{ marginBottom: "-4px" }}
                    src={visible}
                    alt="visible"
                  />
                </button>
                <button
                  className="setting__item_button"
                  style={{ border: visibility === false ? '2px solid #b5f231' : 'none' }}
                  onClick={(e) => {
                    changeVisibility(false);
                    handleClick(e);
                  }}
                >
                  <img
                    className="setting__item_icon"
                    style={{ marginBottom: "-5px" }}
                    src={invisible}
                    alt="invisible"
                  />
                </button>
              </div>
            </div>


            <a
              className="setting__item"
              style={{ border: "none", textDecoration:"none" }}
              href="#"
              onClick={handlePolicyClick}
            >
              <div className="policy_block">
                <p className="setting__item_p"  style={{ textAlign:"left"}}>{t("Policy")}</p>
                <img className="policy_icon" src={arrow} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;