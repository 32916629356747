import { useState, useEffect } from "react";

const useLeaderboard = (filter, tgId, avatarChanged, refreshUser, genderChanged, gameOver) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const initData = window.Telegram.WebApp.initData;
        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];
        const encodedDataCheckString = encodeURIComponent(data_check_string);

        const response = await fetch(`https://tg-back.itc-hub.ru/api/v1/leaderboard?filter=${filter}&user_id=${tgId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'data-check-string': initData,

          },
        });

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Fetch error:", error);
        setError(error);
      }
      setLoading(false);
    };
  
    fetchData();
  }, [filter, tgId, avatarChanged, refreshUser, genderChanged, gameOver]);
  

  return { data, loading, error };
};

export default useLeaderboard;