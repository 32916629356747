import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import coin3 from "../../images/coin3.svg";
import { items } from "../../utils/items";
import ShopModal from "../modals/shopModal/shopModal";
import UserHeader from "../userHeader/userHeader";
import GoMain from "../../images/GoMain.svg";
import people from "../../images/people.svg";
import shop from "../../images/bag.svg";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useLocation } from 'react-router-dom';

const ShopPage = ({ user, gender, onGenderChange, tgId, handleAvatarChanged,
  refreshUser,
  setRefreshUser,
  setGenderChanged,
  genderChanged,  }) => {
  const { t } = useTranslation(); // Initializing useTranslation hook
  const [isLoading, setIsLoading] = useState(true);
  const [impactOccurred, notificationOccurred, selectionChanged] =
  useHapticFeedback();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadedItems, setLoadedItems] = useState([]); // State to track loaded items
  const location = useLocation();

  useEffect(() => {
    // Checking image load
    const imagesLoaded = items.map((item) => {
      const img = new Image();
      img.src = item.image;
      return new Promise((resolve, reject) => {
        img.onload = () => {
          setLoadedItems((prevLoadedItems) => [...prevLoadedItems, item.id]);
          resolve();
        };
        img.onerror = reject;
      });
    });

    Promise.all(imagesLoaded)
      .then(() => setIsLoading(true))
      .catch(() => setIsLoading(true));
  }, []); // Run check on component mount

  const handleItemClick = (item) => {
    const tg = window.Telegram.WebApp;
    tg.setHeaderColor("rgba(72, 72, 71)");
    impactOccurred("soft");
    setSelectedItem(item);
  };

  const closeModal = () => {
    setSelectedItem(null);
    const tg = window.Telegram.WebApp;
    tg.setHeaderColor("#fffbf9");
  };

  const handleGoLobby = () => {
    impactOccurred("soft");
    navigate("/lobby");
  };

  const handleGoMain = () => {
    impactOccurred("soft");
    navigate("/main");
  };

  const handleGoShop = () => {
    impactOccurred("soft");
    navigate("/shop");
  };

  const getBackgroundClass = (type) => {
    switch (type) {
      case "watch":
        return "watch__back";
      case "ring":
        return "ring__back";
      case "bracelet":
        return "bracelet__back";
      default:
        return "";
    }
  };

  const truncateDescription = (description) => {
    return description.length > 77 ? description.slice(0, 77) + "..." : description;
  };

  useEffect(() => {
    const shopItems = document.querySelector('.shop__items');
    
    // Начальная прокрутка вниз
    shopItems.scrollTo({ top: 300, behavior: 'smooth' });

    // Прокрутка обратно вверх через 2 секунды
    const timer = setTimeout(() => {
      shopItems.scrollTo({ top: 0, behavior: 'smooth' });
    }, 600);

    // Очистка таймера при размонтировании компонента
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={`main__block ${selectedItem ? "modal-open" : ""}`}>
        <UserHeader
          user={user}
          tgId={tgId}
          gender={gender}
          onGenderChange={onGenderChange}
          handleAvatarChanged={handleAvatarChanged}
          refreshUser={refreshUser}
          setRefreshUser={setRefreshUser}
          setGenderChanged={setGenderChanged}
          genderChanged={genderChanged}
        />
        <div className="shop__block">
          <h2 className="shop__h">{t("SHOP")}</h2>
          <div className="shop__items" >
            {items.map((item) => (
              <div
                key={item.id}
                className="shop__item"
                onClick={() => handleItemClick(item)}
              >
                <div className={`icon__back ${getBackgroundClass(item.type)}`}>
                  {loadedItems.includes(item.id) ? (
                    <img
                      className="shop__item_icon"
                      src={item.image}
                      alt={item.name}
                    />
                  ) : (
                    <div className="loading-spinner"></div>
                  )}
                </div>
                <div className="shop__item_about-block">
                  <p className="shop__item_h">{item.name}</p>
                  <p className="shop__item_about">
                    {truncateDescription(t(`items.${item.id}.description`))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="navigation__block">
          <button className="navigation__block_button" onClick={handleGoMain}>
            <img className="navigation__block_icon" src={GoMain} alt="Go Main" />
            <p className="navigation__block_p">{t("Main")}</p>
          </button>
          <button className="navigation__block_button" onClick={handleGoLobby}>
            <img className="navigation__block_icon" src={people} alt="People" />
            <p className="navigation__block_p">{t("Lobby")}</p>
          </button>
          <button className="navigation__block_button" onClick={handleGoShop}>
            <img className="navigation__block_icon" src={shop} alt="Shop" />
            <p className="navigation__block_p">{t("Shop")}</p>
          </button>
        </div>
        {selectedItem && (
          <div className="modal__overlay" onClick={closeModal}>
            <div
              className="modal__content"
              onClick={(e) => e.stopPropagation()}
            >
              <ShopModal
                item={selectedItem}
                getBackgroundClass={getBackgroundClass}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShopPage;
