import React, { useState, useEffect } from "react";
import confirmBlack from "../../../images/confirmBlack.svg";
import { useTranslation } from "react-i18next";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const SelectionPanel = ({ onConfirm, tgId, noMoveUser, noMoveOpponent, opponentData}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();

  const handleSelection = (option) => {
    impactOccurred("soft");
    setSelectedOption(option);
  };

  const handleConfirm = () => {
    impactOccurred("soft");
    if (selectedOption) {
      const initData = window.Telegram.WebApp.initData;

      onConfirm({
        action: "make_move",
        move: selectedOption,
        user_id: tgId,
        "data-check-string": initData,

      });
    }
  };

  useEffect(() => {
    if (noMoveUser === true) {
      const initData = window.Telegram.WebApp.initData;

      onConfirm({
        action: "make_move",
        move: "no move",
        user_id: tgId,
        "data-check-string": initData,

      });
    }
  }, [noMoveUser]);

  /*useEffect(() => {
    console.log()
    if (noMoveOpponent === true) {
      onConfirm({
        action: "make_move",
        move: "no move",
        user_id: opponentData?.tg_id,
      });
    }
  }, [noMoveOpponent]);*/

  return (
    <div className="selectionPanel">
      <div className="selection__buttons">
        <button
          className={`selection__button ${
            selectedOption === "rock" ? "selected" : ""
          }`}
          onClick={() => handleSelection("rock")}
        >
          {t("Rock")}
        </button>
        <button
          className={`selection__button ${
            selectedOption === "scissors" ? "selected" : ""
          }`}
          onClick={() => handleSelection("scissors")}
        >
          {t("Scissors")}
        </button>
        <button
          className={`selection__button ${
            selectedOption === "paper" ? "selected" : ""
          }`}
          onClick={() => handleSelection("paper")}
        >
          {t("Paper")}
        </button>
      </div>
      <button className="selection__button_confirm" onClick={handleConfirm}>
        <p className="selection__button_confirm_text">{t("Confirm")}</p>
        <img
          className="selection__button_confirm_pic"
          src={confirmBlack}
          alt="Confirm"
        />
      </button>
    </div>
  );
};

export default SelectionPanel;
