import React from "react";
import qr from "../images/qr.png";
const QrCodeDisplay = () => {
  const pageElement = document.querySelector(".page");
  pageElement.style.margin = "0";
  pageElement.style.height = "100%";

  const root = document.getElementById("root");
  root.style.height = "100%";

  document.documentElement.style.height = "100%"; // Устанавливаем высоту для html
  document.body.style.height = "100%"; // Устанавливаем высоту для body

  return (
    <div className="qr-code-container">
      <p className="qr-code_text">Play on your mobile</p>
      <div className="qr-code">
        <img
          style={{
            backgroundColor: "#fff",
            borderRadius: "23px",
            height: "280px",
            width: "280px",
          }}
          src={qr}
        />
      </div>
      <p className="qr-code_text">@rpsgameio_bot</p>
    </div>
  );
};

export default QrCodeDisplay;
