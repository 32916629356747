import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import backButton1 from "../../../images/backButton1.svg";
import lock from "../../../images/lock1.svg";
import open from "../../../images/open.svg";
import greenTaskSmall from "../../../images/greenTaskSmall.png";
import greenTaskBig from "../../../images/greenTaskBig.png";
import orangeBig from "../../../images/orangeBig2.png";
import orangeSmall from "../../../images/orangeSmall2.png";
import orangeBig2 from "../../../images/orangeBig.png";
import orangeSmall2 from "../../../images/orangeSmall.png";
import pinkBig from "../../../images/pinkBig2.png";
import pinkSmall from "../../../images/pinkSmall2.png";
import pinkBig2 from "../../../images/pinkBig.png";
import pinkSmall2 from "../../../images/pinkSmall.png";
import blueBig from "../../../images/blueBig.png";
import blueSmall from "../../../images/blueSmall.png";
import mech from "../../../images/mech.png";
import meshok from "../../../images/meshok.png";
import tg from "../../../images/tg.png";
import message from "../../../images/tasks/message.png";
import coins from "../../../images/tasks/coins.png";
import goal from "../../../images/tasks/goal.png";
import closeBeta from "../../../images/tasks/closeBeta.png";
import openBeta from "../../../images/tasks/openBeta.png";
import cubok from "../../../images/tasks/cubok.png";
import { useLanguage } from "../../../utils/LanguageContext";
import closeIcon from "../../../images/CloseModal.png";

import rocket from "../../../images/tasks/rocket.png";
import start from "../../../images/startArrow.png";
import unlock from "../../../images/Unlock.svg";
import lock2 from "../../../images/lock2.svg";
import pioner from "../../../images/pioner.png";
import openModal from "../../../images/tasks/Flag.png";
import closedTask from "../../../images/closedTask.png";

import SettingsModal from "../settingsModal/settingsModal";

import roseBig from "../../../images/roseBig.png";
import roseSmall from "../../../images/roseSmall.png";
import redBig from "../../../images/redBig.png";
import redSmall from "../../../images/redSmall.png";
import bBig from "../../../images/bBig.png";
import bSmall from "../../../images/bSmall.png";
import arrow from "../../../images/arrowright.svg";
import coin3 from "../../../images/coin3.svg";
import useAchievementsData from "../../../hooks/useGetTasks";

const Modal = ({
  isOpen,
  onClose,
  subtask,
  tgId,
  activeTask,
  achievements,
  refreshUser,
  setRefreshUser,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false); // For loading state
  const [buttonState, setButtonState] = useState({
    color: "black",
    label: t("Start"),
  });
  const { language, changeLanguage } = useLanguage();

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal__overlay_settings")) {
      onClose();
    }
  };

  const getTaskIconModal = (taskId) => {
    switch (taskId) {
      case 1:
        return activeTask === 1 ? message : message;
      case 2:
        return activeTask === 2 ? coins : coins;
      case 3:
        return activeTask === 3 ? goal : goal;
      case 92:
        return activeTask === 92 ? pioner : pioner;
      case 95:
        return activeTask === 95 ? openModal : openModal;
      default:
        return closedTask;
    }
  };

  const handleClaimAchievement = async () => {
    if (subtask.achievement.id === 1138 && subtask.completed === false && subtask.received === false) {
     
      try {
        if (language === "ru") {
          window.Telegram.WebApp.openLink("https://t.me/test_bot_subscripe");
        } else {
          window.Telegram.WebApp.openLink("https://t.me/privaterpsgametest");
        }
      } catch (error) {
        console.error("Error during redirection:", error);
      }
      
    } else {
      setLoading(true); // Show loader
      setRefreshUser((prev) => !prev);
      try {
        const achievementId = subtask.achievement.id;
        const url = `https://tg-back.itc-hub.ru/api/v1/userachievement?tg_id=${tgId}&achievement_id=${achievementId}`;
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "data-check-string": window.Telegram.WebApp.initData,
          },
        });
  
        const result = await response.json();
  
        if (response.ok) {
          if (result.success) {
            setButtonState({ backgroundColor: "gray", label: t("Done") });
            setRefreshUser((prev) => !prev);
          }
        } else {
          handleErrors(result.error);
        }
      } catch (error) {
        console.log("Что-то пошло не так");
      } finally {
        setLoading(false); // Hide loader
      }
    }
  };
  

  const handleErrors = (error) => {
    switch (error) {
      case "Achievement не найден":
      case "UserAchievement не найден":
        alert(t("Что-то пошло не так"));
        break;
      case "Нельзя получить данное достижение":
        alert(t("Что-то пошло не так"));
        break;
      case "Достижение уже получено":
        setButtonState({ backgroundColor: "gray", label: t("Done") });
        break;
      case "Задание не выполнено.":
        setButtonState({
          backgroundColor: "black",
          label: t("Задание не выполнено"),
        });
        break;
      default:
        alert("Неизвестная ошибка");
    }
  };

  useEffect(() => {
    if (subtask && subtask.completed && !subtask.received) {
      setButtonState({
        backgroundColor: "rgba(181, 242, 49, 1)",
        color: "black",
        label: t("Claim"),
      });
    } else if (subtask && subtask.completed && subtask.received) {
      setButtonState({
        backgroundColor: "gray",
        color: "white",
        label: t("Done"),
      });
    } else {
      setButtonState({
        backgroundColor: "black",
        color: "white",
        label: t("Start"),
      });
    }
  }, [subtask]);

  if (!isOpen) return null;

  return (
    <div className="modal__overlay_settings" onClick={handleOverlayClick}>
      <div className="modal-content">
        <div className="modal-content-block">
          <button className="close_task_button" style={{background:"transparent", border:"none"}} onClick={onClose}>
          <img className="close_task" src={closeIcon}/>
          </button>
          <img
            className="modal-content_icon"
            src={getTaskIconModal(subtask.achievement.id)}
          />
          <h3 className="modal-content_h3">
            {t(subtask.achievement.ru_title)}
          </h3>
          <h4 className="modal-content_h">
            {t(subtask.achievement.condition)}
          </h4>
          <span
            className="subtask-reward"
            style={{ fontSize: "16px", fontWeight: "700" }}
          >
            +{subtask.achievement.points}{" "}
            <img
              style={{ width: "15.28px", height: "15.28px" }}
              src={coin3}
              alt="coin-icon"
            />
          </span>
          <button
            onClick={handleClaimAchievement}
            disabled={
              subtask.achievement.open === false ||
              buttonState.label === t("Done") ||
              loading
            } // Disable when subtask is closed, label is "Done", or when loading
            style={{
              backgroundColor: buttonState.backgroundColor,
              color: buttonState.color,
            }}
          >
            {loading ? (
              <div className="loader" />
            ) : (
              <>
                {subtask.achievement.open === false
                  ? t("Closed")
                  : buttonState.label}

                {buttonState.label === t("Start") &&
                  subtask.achievement.open !== false && (
                    <img className="button-gif" src={start} alt="start-icon" />
                  )}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const TaskList = ({
  onClose,
  loading,
  handleOpenAvatarModal,
  truncateString,
  formatBalance,
  userBalance,
  showSettings,
  handleCloseSettings,
  onGenderChange,
  setGenderChanged,
  genderChanged,
  handleOpenSettings,
  settings,
  user,
  tgId,
  fetchedUser,
  baseURL,
  userAvatar,
  isModalOpen,
  setIsModalOpen,
  refreshUser,
  setRefreshUser,
}) => {
  const [activeTask, setActiveTask] = useState(null);
  const taskListRef = useRef(null);
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const { t } = useTranslation();
  const { achievements, error, successMessage, claimAchievement } =
    useAchievementsData(tgId, refreshUser); // Hook to manage achievements
  const [isLoading, setIsLoading] = useState(true); // Добавляем состояние загрузки

  useEffect(() => {
    // Эмуляция задержки для показа спиннера
    const timer = setTimeout(() => {
      setIsLoading(false); // После загрузки убираем спиннер
    }, 1000); // Можно настроить длительность загрузки

    return () => clearTimeout(timer);
  }, []);

  const toggleTask = (taskId) => {
    setActiveTask(activeTask === taskId ? null : taskId);
  };

  const openModal = (subtask) => {
    setSelectedSubtask(subtask);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSubtask(null);
  };

  const getTaskBackground = (taskId) => {
    switch (taskId) {
      case 1:
        return activeTask === 1 ? `url(${redBig})` : `url(${redSmall})`;
      case 2:
        return activeTask === 2 ? `url(${roseBig})` : `url(${roseSmall})`;
      case 3:
        return activeTask === 3
          ? `url(${greenTaskBig})`
          : `url(${greenTaskSmall})`;
      case 4:
        return activeTask === 4 ? `url(${bBig})` : `url(${bSmall})`;
      case 5:
        return activeTask === 5 ? `url(${orangeBig})` : `url(${orangeSmall})`;
      case 6:
        return activeTask === 6 ? `url(${orangeBig2})` : `url(${orangeSmall2})`;
      case 7:
        return activeTask === 7 ? `url(${pinkBig})` : `url(${pinkSmall})`;
      default:
        return `url(${greenTaskSmall})`;
    }
  };
  const getTaskColor = (taskId) => {
    switch (taskId) {
      case 1:
        return activeTask === 1 ? "#F28482" : "#F28482";
      case 2:
        return activeTask === 2 ? "#E0C1B3" : "#E0C1B3";
      case 3:
        return activeTask === 3 ? "#9deb36" : "#9deb36";
      case 4:
        return activeTask === 4 ? "#9CFFFA" : "#9CFFFA";
      case 5:
        return activeTask === 5 ? "#EDA3FD" : "#EDA3FD";
      case 6:
        return activeTask === 6 ? "#FDE07A" : "#FDE07A";
      case 7:
        return activeTask === 7 ? "#A19EFA" : "#A19EFA";
      default:
        return "transparent";
    }
  };

  const getTaskIcon = (taskId) => {
    switch (taskId) {
      case 1:
        return activeTask === 1 ? tg : tg;
      case 2:
        return activeTask === 2 ? meshok : meshok;
      case 3:
        return activeTask === 3 ? mech : mech;
      case 92:
        return activeTask === 92 ? cubok : cubok;
      case 95:
        return activeTask === 95 ? closeBeta : closeBeta;
      default:
        return closedTask;
    }
  };

  useEffect(() => {
    if (activeTask >= 4 && activeTask <= 7 && taskListRef.current) {
      const activeTaskElement = document.getElementById(`task-${activeTask}`);
      if (activeTaskElement) {
        taskListRef.current.scrollTo({
          top: activeTaskElement.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [activeTask]);

  return (
    <>
      <div className="daily__modal_buttons">
        <button className="backButton_daily" onClick={onClose}>
          <img className="backButton_daily_icon" src={backButton1} alt="Back" />
        </button>
        <>
          {loading ? (
            <div
              className="user__header"
              style={{
                minHeight: "50px",
                maxHeight: "50px",
                minWidth: "220px",
                maxWidth: "220px",
                padding: 0,
                boxShadow: "0px 0px 5px 1px #b5f231",
              }}
            >
              <div className="user__block"></div>
            </div>
          ) : (
            <header
              className="user__header"
              style={{
                minWidth: "220px",
                maxWidth: "220px",
                padding: "9px 12px",
                height: "unset",
              }}
            >
              <div className="user__block" onClick={handleOpenAvatarModal}>
                <img
                  className="user__avatar"
                  src={userAvatar || `${baseURL}${fetchedUser?.avatar?.image}`}
                  alt="Avatar"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 5px 1px #b5f231",
                  }}
                />
                <p className="user__name" style={{ fontSize: "12px" }}>
                  {truncateString(user.username || fetchedUser.username, 25)}
                </p>
              </div>
              <div className="balance__block">
                <img
                  className="balance__icon"
                  src={coin3}
                  alt="Coin icon"
                  style={{ width: "14.42px", height: "14.42px" }}
                />
                <p className="balance__amount" style={{ fontSize: "12px" }}>
                  {formatBalance(userBalance)}
                </p>
              </div>
            </header>
          )}
        </>
        {showSettings && (
          <SettingsModal
            onClose={handleCloseSettings}
            onGenderChange={onGenderChange}
            user={user}
            tgId={tgId}
            fetchedUser={fetchedUser}
            setGenderChanged={setGenderChanged}
            genderChanged={genderChanged}
          />
        )}

        <button className="settings__button" onClick={handleOpenSettings}>
          <img
            className="settings__button_icon"
            src={settings}
            alt="Settings"
          />
        </button>
      </div>
      <div className="fixed__bonuses" style={{ position: "relative" }}>
        <div className="tasks__block_h">
          <h3 className="bonuses__h" style={{ marginBottom: "5px" }}>
            {t("TASKS")}
          </h3>
        </div>
        <p className="ref__text_p" style={{ marginBottom: "0px" }}>
          {t("Complete daily tasks and get bonuses")}
        </p>
      </div>

      <div className="task-list" ref={taskListRef}>
        {isLoading ? (
          <div className="loading-spinner_task-list"></div>
        ) : (
          achievements.map((task) => (
            <div
              key={task.id}
              id={`task-${task.id}`}
              className={`task ${activeTask === task.id ? "active" : ""}`}
              style={{
                backgroundImage: getTaskBackground(task.id),
                backgroundColor: getTaskColor(task.id),
                backgroundSize: "cover",
                transition: "background 0.6s ease",
              }}
            >
              <div
                className="task-header"
                onClick={() => task.open && toggleTask(task.id)}
              >
                <div className="task-open-box">
                  <img
                    className={`open-icon ${
                      activeTask === task.id ? "rotated" : ""
                    }`}
                    src={open}
                    alt="open-icon"
                  />
                  <span className="task-name">{t(task.name)}</span>
                </div>
                <div className="task-lock-box">
                  <span className="task-complited">{`${task.received_count} / ${task.total_count}`}</span>
                  <img
                    src={task.open ? unlock : lock}
                    className="lock-icon"
                    alt={task.open ? "unlock-icon" : "lock-icon"}
                  />
                </div>
              </div>
              {/* Проверяем если task.open === true */}
              {task.open && activeTask === task.id && (
                <div
                  className="subtasks-container"
                  style={{ transition: "all 0.3s ease" }}
                >
                  <div className="subtasks-list">
                    {task.user_achievements.length > 0 ? (
                      task.user_achievements.map((subtask, index) => (
                        <div
                          key={index}
                          className="subtask"
                          onClick={() => openModal(subtask)}
                        >
                          <div className="subtask-content">
                            <img
                              className="subtask-icon"
                              src={getTaskIcon(subtask.achievement.id)}
                            />
                            <div className="subtask-text">
                              <span className="task-subtask">
                                {t(subtask.achievement.ru_title)}
                              </span>
                              <span className="subtask-reward">
                                {subtask.achievement.points}{" "}
                                <img
                                  style={{ width: "8.28px", height: "8.28px" }}
                                  src={coin3}
                                  alt="coin-icon"
                                />
                              </span>
                            </div>
                          </div>
                          <button
                            className={`start-btn ${
                              subtask.completed && subtask.received
                                ? "grey-btn" // если completed и received true
                                : subtask.completed && !subtask.received
                                ? "green-btn" // если completed true и received false
                                : "black-btn" // если оба false
                            }`}
                          >
                            {subtask.achievement.open === false ? (
                              <p className="start-btn_p">{t("Closed")}</p>
                            ) : subtask.completed && subtask.received ? (
                              <p className="start-btn_p">{t("Готово")}</p>
                            ) : subtask.completed && !subtask.received ? (
                              <p className="start-btn_p">{t("Забрать")}</p>
                            ) : (
                              <>
                                <p className="start-btn_p">{t("Start")}</p>
                                <img
                                  className="start-btn_arrow"
                                  src={arrow}
                                  alt="arrow"
                                />
                              </>
                            )}
                          </button>
                        </div>
                      ))
                    ) : (
                      <p>No subtasks available</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        subtask={selectedSubtask}
        tgId={tgId}
        activeTask={activeTask}
        achievements={achievements}
        refreshUser={refreshUser}
        setRefreshUser={setRefreshUser}
      />
    </>
  );
};

export default TaskList;
