import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import coin3 from "../../images/coin3.svg";
import next1 from "../../images/next1.svg";
import GoMain from "../../images/GoMain.svg";
import girl from "../../images/girl.pdf";
import people from "../../images/people.svg";
import shop from "../../images/bag.svg";
import UserHeader from "../userHeader/userHeader";
import BetModal from "../modals/betModal/betModal";
import useLeaderboard from "../../hooks/useLeaderboard";
import gamesIcon from "../../images/games.png";
import winsIcon from "../../images/wins.png";
import LoadingPage from "../loadingPage/loadingPage";
import useUserGet from "../../hooks/useUserGet";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

const MainPage = ({ user, gender, tgId, isBan, setGameIsOver, handleGameOver,  }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isBetModalOpen, setIsBetModalOpen] = useState(false);
  const [filter, setFilter] = useState("win");
  const [currentUser, setCurrentUser] = useState(null);
  const [noLobby, setNoLobby] = useState(false);
  const [lobbyFound, setLobbyFound] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [lobbyConnected, setLobbyConnected] = useState(null);
  const [avatarChanged, setAvatarChanged] = useState();
  const [refreshUser, setRefreshUser] = useState(false);
  const [userPosition, setUserPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [goGame, setGoGame] = useState(false);
  const [lobbyDataMessage, setLobbyDataMessage] = useState({});
  const [lobbyData, setLobbyData] = useState({});
  const [socket, setSocket] = useState(null);
  const [genderChanged, setGenderChanged] = useState(null);
  const [userExit, setUserExit] = useState(false);
  const [ownerExit, setOwnerExit] = useState(false);
  const [noConfirm, setNoConfirm] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownContent, setDropdownContent] = useState("");
 
  const { gameOver } = location.state || {};
  const {
    user: fetchedUser,
    error: userError,
    getUser,
  } = useUserGet(tgId, refreshUser, genderChanged, gameOver);
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const { data, error } = useLeaderboard(
    filter,
    tgId,
    avatarChanged,
    refreshUser,
    genderChanged,
    gameOver
  );

  useEffect(() => {
    if(gameOver){
      handleGameOver();
    }
  }, [gameOver]);

  const baseURL = "https://tg-back.itc-hub.ru";

  const handleAvatarChanged = (newAvatar) => {
    setTimeout(() => {
      setAvatarChanged(newAvatar);
    }, 600); 
  };

  useEffect(() => {
    if (fetchedUser) {
    }
  }, [fetchedUser]);

  /*useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("modal") === "bet") {
      const tg = window.Telegram.WebApp;
      tg.setHeaderColor("rgba(72, 72, 71)");
      setIsBetModalOpen(true);
    }
  }, [location.search]);*/


  
  const handleGoRandomGame = () => {
    impactOccurred("soft");
    setIsBetModalOpen(true);
    //navigate("/main?modal=bet");
    const tg = window.Telegram.WebApp;
      tg.setHeaderColor("rgba(72, 72, 71)");
      setIsBetModalOpen(true);
  };

  const handleGoMain = () => {
    impactOccurred("soft");
    navigate("/main");
  };

  const handleGoShop = () => {
    impactOccurred("soft");
    navigate("/shop");
  };

  const handleGoLobby = () => {
    impactOccurred("soft");
    navigate("/lobby");
  };

  const closeModal = () => {
    navigate("/main");
    setIsBetModalOpen(false);
   /* const tg = window.Telegram.WebApp;
    tg.setHeaderColor("#fffbf9");*/
  };

  /*const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };*/

  const handleSearchLobby = (searchParams) => {
    const socket = new WebSocket("wss://tg-back.itc-hub.ru/ws/lobby_search/");

    socket.onopen = () => {
      console.log("WebSocket connected.");
      socket.send(JSON.stringify(searchParams));
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.message === "No lobby found, waiting for one to appear...") {
        setNoLobby(true);
      } else if (message.message === "Lobby found") {
        setLoading(true);
        setLobbyDataMessage(message);
        setLobbyData(message);
        //navigate("/loading", { state: { message } });
      }
      if (message.message === "No lobby found, waiting for one to appear...") {
        //setNoLobby(true);
        //console.log("No lobby TEST");
      }
      if (message.message === "Waiting for the other user to confirm") {
        setWaiting(true);
      }
      if (message.message === "Waiting for the other user to confirm") {
        setNoConfirm(true);
      }
      if (message.message === "Game started") {
        setGoGame(true);
        setNoConfirm(false);
      }
     
      if (message.message === "User exit lobby") {
        const owner = message.lobby_owner;
        const exitUser = message.user;
        if (exitUser !== tgId && owner === false) {
          setUserExit(true);
        }
        if (exitUser !== tgId && owner === true) {
          setOwnerExit(true);
        }
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket closed:", event);
    };
    setSocket(socket);
  };

  const handleConfirm = (params) => {

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
    }
  };

  const handleExit = (params) => {

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
    }
  };

  useEffect(() => {
    if (data) {
      if (data.pulls) {
        const wins_pull = data.pulls.commission_leaderboard_1;
        const games_pull = data.pulls.commission_leaderboard_2;
        const points_pull = data.pulls.commission_leaderboard_3;
      }
      if (data.current_user_info && data.current_user_info.user) {
       
        setCurrentUser(data.current_user_info.user);
        setUserPosition(data.current_user_info.position);
      } else if (data.users) {
        const foundUser = data.users.find((user) => user.tg_id === tgId);
        setCurrentUser(foundUser);
      }
    }
  }, [data]);

  const formatBalance = (balance) => {
    if (balance < 10000000) {
      return balance;
    }
    const millionBalance = (balance / 1000000).toFixed(1);
    return `${millionBalance}M`;
  };

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  };

  const getDisplayValue = (user) => {
    switch (filter) {
      case "points":
        return formatBalance(user.balance);
      case "games":
        return user.games_played;
      case "win":
        return user.games_won;
      default:
        return user.balance;
    }
  };

  const getIconSrc = () => {
    switch (filter) {
      case "points":
        return coin3;
      case "games":
        return gamesIcon;
      case "win":
        return winsIcon;
      default:
        return coin3;
    }
  };

  const handleFilterChange = (newFilter) => {
    impactOccurred("soft");
    if (newFilter === filter) {
      // Toggle dropdown visibility and content
      setShowDropdown((prevState) => !prevState);
    } else {
      // Close dropdown and set new filter
      setShowDropdown(false);
      setFilter(newFilter);
    }

    // Set dropdown content based on the new filter
    switch (newFilter) {
      case "games":
        setDropdownContent(data?.pulls?.commission_leaderboard_2 || "");
        break;
      case "win":
        setDropdownContent(data?.pulls?.commission_leaderboard_1 || "");
        break;
      case "points":
        setDropdownContent(data?.pulls?.commission_leaderboard_3 || "");
        break;
      default:
        setDropdownContent("");
    }
  };

  const formatNumber = (number) => {
    if (number >= 100000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (number >= 10000000) {
      return (number / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
    } else if (number >= 100000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    } else if (number >= 10000) {
      return (number / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
    } else {
      return number.toString();
    }
  };
  

  return (
    <>
      {!loading ? (
        <div className={`main__block ${isBetModalOpen ? "modal-open" : ""}`}>
          <UserHeader
            tgId={tgId}
            user={user}
            gender={gender}
            fetchedUser={fetchedUser}
            handleAvatarChanged={handleAvatarChanged}
            refreshUser={refreshUser}
            setRefreshUser={setRefreshUser}
            setGenderChanged={setGenderChanged}
            genderChanged={genderChanged}
            gameOver={gameOver}
          />
          <div className="games__block">
            <div className="games__nav">
              <div
                className={`button__div ${
                  filter === "win" && showDropdown ? "pull" : ""
                }`}
              >
                <button
                  className={`games__button ${
                    filter === "win" ? "selected_button" : ""
                  }`}
                  onClick={() => handleFilterChange("win")}
                >
                  {t("WIN")}
                </button>
                {filter === "win" && showDropdown && (
                  <div className="pull__popup">
                    <div className="pull__popup_triangle" />
                    <div className="pull__popup_content">
                      <p className="popup_balance_p">{`+ ${formatNumber(dropdownContent)}`}</p>
                      <img className="popup_balance_coin" src={coin3}/>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`button__div ${
                  filter === "games" && showDropdown ? "pull" : ""
                }`}
              >
                <button
                  className={`games__button ${
                    filter === "games" ? "selected_button" : ""
                  }`}
                  style={{ padding: "12px 13px" }}
                  onClick={() => handleFilterChange("games")}
                >
                  {t("GAMES")}
                </button>
                {filter === "games" && showDropdown && (
                  <div className="pull__popup">
                    <div className="pull__popup_triangle" />
                    <div className="pull__popup_content">
                      <p className="popup_balance_p">{`+ ${formatNumber(dropdownContent)}`}</p>
                      <img className="popup_balance_coin" src={coin3}/>

                    </div>
                  </div>
                )}
              </div>
              <div
                className={`button__div ${
                  filter === "points" && showDropdown ? "pull" : ""
                }`}
              >
                <button
                  className={`games__button ${
                    filter === "points" ? "selected_button" : ""
                  }`}
                  style={{ padding: "12px 16px" }}
                  onClick={() => handleFilterChange("points")}
                >
                  {t("POINTS")}
                </button>
                {filter === "points" && showDropdown && (
                  <div className="pull__popup">
                    <div className="pull__popup_triangle" />
                    <div className="pull__popup_content">
                      <p className="popup_balance_p">{`+ ${formatNumber(dropdownContent)}`}</p>
                      <img className="popup_balance_coin" src={coin3}/>

                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="games__table">
              <div className="games__table_box">
                {error && <p className="error-message">{t("Oops, there was an error :(")}</p>}
                {data &&
                  data.users.map((user, index) => (
                    <div className="games__player" key={user.tg_id}>
                      <div className="player__box">
                        <p className="player__number">{index + 1}</p>
                        <div className="player__block">
                          <img
                            className="player__avatar"
                            src={`${baseURL}${user?.avatar?.image}`}
                            alt="avatar"
                          />
                          <p className="player__name">
                            {truncateString(user.username || "Player", 17)}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`player__balance ${
                          filter !== "points" ? "non-points" : ""
                        }`}
                      >
                        <img
                          className="player__balance_icon"
                          src={getIconSrc()}
                          alt="balance"
                        />
                        <p className="player__balance_amount">
                          {getDisplayValue(user)}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              {currentUser && (
                <div className="games__player fixed-player-bar">
                  <div className="player__box">
                    <p className="player__number">{data ? userPosition : ""}</p>
                    <div className="player__block">
                      <img
                        className="player__avatar"
                        src={
                          currentUser.avatar && currentUser.avatar.image
                            ? `${baseURL}${currentUser.avatar.image}`
                            : { girl }
                        }
                        alt="avatar"
                      />
                      <p className="player__name">
                        {truncateString(currentUser.username || "Player", 17)}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`player__balance ${
                      filter !== "points" ? "non-points" : ""
                    }`}
                  >
                    <img
                      className="player__balance_icon"
                      src={getIconSrc()}
                      alt="balance"
                    />
                    <p className="player__balance_amount">
                      {getDisplayValue(currentUser)}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <button
            className={`play__button ${isBan ? "disabled" : ""}`}
            onClick={isBan ? null : handleGoRandomGame}
            disabled={isBan}
          >
            <p className="play__button_p">{t("Random game")}</p>
            <img className="play__button_icon" src={next1} alt="next" />
          </button>

          <div className="navigation__block">
            <button className="navigation__block_button" onClick={handleGoMain}>
              <img className="navigation__block_icon" src={GoMain} alt="main" />
              <p className="navigation__block_p">{t("Main")}</p>
            </button>
            <button
              className="navigation__block_button"
              onClick={handleGoLobby}
            >
              <img
                className="navigation__block_icon"
                src={people}
                alt="lobby"
              />
              <p className="navigation__block_p">{t("Lobby")}</p>
            </button>
            <button className="navigation__block_button" onClick={handleGoShop}>
              <img className="navigation__block_icon" src={shop} alt="shop" />
              <p className="navigation__block_p">{t("Shop")}</p>
            </button>
          </div>

          {isBetModalOpen && (
            <div className="modal__overlay" onClick={closeModal}>
              <div
                className="modal__content"
                style={{animation:"none"}}
                onClick={(e) => e.stopPropagation()}
              >
                <div style={{ pointerEvents: "auto" }}>
                  <BetModal
                    lobbyConnected={lobbyConnected}
                    tgId={tgId}
                    onSearchLobby={handleSearchLobby}
                    noLobby={noLobby}
                    setNoLobby={setNoLobby}
                    lobbyFound={lobbyFound}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <LoadingPage
          lobbyDataMessage={lobbyDataMessage}
          tgId={tgId}
          onConfirm={handleConfirm}
          onExit={handleExit}
          waiting={waiting}
          goGame={goGame}
          setUserExit={setUserExit}
          userExit={userExit}
          ownerExit={ownerExit}
          setOwnerExit={setOwnerExit}
          noConfirm={noConfirm}
          setNoConfirm={setNoConfirm}
          
        />
      )}
    </>
  );
};

export default MainPage;
