import React, { useEffect, useState, useRef } from "react";
import backButton1 from "../../images/backButton1.svg";
import confirmBlack from "../../images/confirmBlack.svg";
import useUserGet from "../../hooks/useUserGet";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserHeader from "../userHeader/userHeader";
import WarningModal from "../modals/warningModal/warningModal";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const LoadingPage = ({
  tgId,
  lobbyData,
  lobbyDataMessage,
  lobbyOverall,
  onConfirm,
  waiting,
  goGame,
  onExit,
  setUserExit,
  userExit,
  ownerExit,
  setOwnerExit,
  onExitNoConnection,
  socket,
  noConfirm,
  setNoConfirm
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [opponentConnected, setOpponentConnected] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [userAvatar, setUserAvatar] = useState("");
  const [userName, setUserName] = useState("");
  const [lobbyInfo, setLobbyInfo] = useState(null);
  const [lobbyInformation, setLobbyInformation] = useState(null);
  const { user: fetchedUser } = useUserGet(tgId);
  const { lobby } = location.state || {};
  const { message } = location.state || {};
  const { lobbysId } = location.state || {};
  const [error, setError] = useState(false);
 const [userWhoConnect, setUserWhoConnect] = useState(null);
 const [userWhoMadeLobby, setUserWhoMadeLobby] = useState(null);
  const [warning, setWarning] = useState(false);
  const [noExit, setNoExit] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [impactOccurred, notificationOccurred, selectionChanged] =
  useHapticFeedback();
  const [waitingText, setWaitingText] = useState(
    t("Waiting for the player to connect...")
  );
  const timerRef = useRef(null);
  const baseURL = "https://tg-back.itc-hub.ru";
  window.Telegram.WebApp.setHeaderColor("#fff");

  useEffect(() => {
    if (fetchedUser && fetchedUser.avatar) {
      const fullAvatarUrl = `${baseURL}${fetchedUser.avatar.image}`;
      setUserAvatar(fullAvatarUrl);
      setUserName(fetchedUser.username);
    }
  }, [fetchedUser]);

  useEffect(() => {
    if (lobbyData) {
      setOpponentConnected(true);
      setLobbyInformation(lobbyData);

      setUserWhoMadeLobby(lobbyData.lobby_info);
    }
    if (lobbyDataMessage) {
      setOpponentConnected(true);
      setLobbyInformation(lobbyDataMessage);
      setUserWhoConnect(lobbyDataMessage.lobby_info);
    }
  }, [lobbyData, lobbyDataMessage]);

  const handleGoGame = () => {
    impactOccurred("soft");
    setShowLoading(true);
    if(userWhoMadeLobby){
      const initData = window.Telegram.WebApp.initData;
    onConfirm({
      action: "confirm_game",
      lobby_id: lobbyInformation.lobby_info.id,
      user_id: tgId,
      "data-check-string": initData,

    });
  }
  if(userWhoConnect){
    setTimeout(() => {
      const initData = window.Telegram.WebApp.initData;

      onConfirm({
        action: "confirm_game",
        lobby_id: lobbyInformation.lobby_info.id,
        user_id: tgId,
        "data-check-string": initData,

      });
    }, 1500); 
  }
    /*if (waiting) {
      setShowLoading(true);
    }*/
  };

  useEffect(() => {
    if (noConfirm === true && !goGame && !noExit) {
      timerRef.current = setTimeout(() => {
        setOpponentConnected(false);
        setWaitingText(
          t("Your opponent has left the lobby. Please, try again.")
        );
        setTimeout(() => {
          navigate("/main");
        }, 2500);
      }, 30000);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [noConfirm, goGame, noExit]);
  
  useEffect(() => {
    if (goGame && noExit && timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [goGame, noExit]);

  useEffect(() => {
    if (goGame) {
      //setNoConfirm(false)
      setTimeout(() => {
        setShowLoading(false);
        if (lobbyData) {
          navigate("/game", { state: { userWhoMadeLobby } } );
          //sessionStorage.setItem('wasReload', 'true');
        }
        if (lobbyDataMessage) {
          navigate("/game", { state: { userWhoConnect} });
          //sessionStorage.setItem('wasReload', 'true');
        }
      }, 1250);
    }
  }, [goGame]);

  useEffect(() => {
    if (ownerExit) {
      setOpponentConnected(false);
      setWaitingText(t("Owner of the game has left the lobby"));
      setTimeout(() => {
        setGameOver(true)
        //localStorage.setItem('gameOver', 'true');
        navigate("/main", { state: { gameOver} });

      }, 2000);
    }

    if (userExit) {
      setOpponentConnected(false);
      setWaitingText(t("Your opponent has left the lobby"));
      setTimeout(() => {
        setWaitingText(t("Waiting for the player to connect..."));
        setUserExit(false);
        setNoExit(true);
      }, 2000);
    }
  }, [userExit, ownerExit, opponentConnected]);

  const handleGoWarning = () => {
    impactOccurred("soft");
    if (opponentConnected) {
      setWarning(true);
    } else {
      setGameOver(true)
      //localStorage.setItem('gameOver', true);
      navigate("/main", { state: { gameOver} });
      socket.close();
    }
  };

  return (
    <>
      {!warning ? (
        <>
          <div className="modal__overlay">
            <div className="Loading">
              <div className="loading__header">
                <button className="header__buton" onClick={handleGoWarning}>
                  <img
                    className="header__button_icon"
                    src={backButton1}
                    alt="Back"
                  />
                </button>
                <h1 className="header__h">{t("Waiting...")}</h1>
              </div>
              {!error ? (
                <div className="loading__screen">
                  <div className="loading__box">
                    {!opponentConnected ? (
                      <p className="loading__box_p">{waitingText}</p>
                    ) : (
                      <>
                        <div className="opponent__block">
                          {lobbyInformation &&
                            lobbyInformation.lobby_info &&
                            lobbyInformation.lobby_info.users &&
                            lobbyInformation.lobby_info.users.map((user) =>
                              user.tg_id !== tgId ? (
                                <img
                                  key={user.tg_id}
                                  className="opponent__block_icon"
                                  src={`${baseURL}${user.avatar.image}`}
                                  alt="Opponent Avatar"
                                />
                              ) : null
                            )}
                        </div>

                        <p className="opponent__block_name">
                          {lobbyInformation &&
                            lobbyInformation.lobby_info &&
                            lobbyInformation.lobby_info.users &&
                            lobbyInformation.lobby_info.users.map(
                              (user) =>
                                user.tg_id !== tgId && (
                                  <React.Fragment key={user.tg_id}>
                                    {user.username}
                                  </React.Fragment>
                                )
                            )}
                        </p>
                      </>
                    )}
                  </div>

                  <div className="information__table">
                    <div className="information__table_cell">
                      <h3 className="information__table_h">{t("Bet")}</h3>
                      <div className="information__table_line"></div>
                      <p className="information__table_amount">
                        {lobbyInformation
                          ? lobbyInformation.lobby_info.stake
                          : lobbyOverall
                          ? lobbyOverall.stake
                          : null}
                      </p>
                    </div>
                    <div className="information__table_cell">
                      <h3 className="information__table_h">{t("Best of")}</h3>
                      <div className="information__table_line"></div>
                      <p className="information__table_amount">
                        {lobbyInformation
                          ? lobbyInformation.lobby_info.max_wins
                          : lobbyOverall
                          ? lobbyOverall.max_wins
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="loading__box">
                    <div className="opponent__block">
                      <img
                        className="opponent__block_icon"
                        src={userAvatar}
                        alt="User Avatar"
                      />
                    </div>
                    <p className="opponent__block_name">{userName}</p>
                  </div>

                  {opponentConnected && (
                    <button
                      className="selection__button_confirm"
                      style={{ width: "unset", height: "50px" }}
                      onClick={handleGoGame}
                      disabled={showLoading} // Добавляем атрибут disabled
                    >
                      <p
                        className="selection__button_confirm_p"
                        style={{ fontSize: "16px" }}
                      >
                        {showLoading ? t("Loading..") : t("Let's go")}
                      </p>
                      <img
                        className="selection__button_confirm_pic"
                        src={confirmBlack}
                        alt="Confirm"
                      />
                    </button>
                  )}
                </div>
              ) : (
                <div className="error_lobby">
                  <p className="error_lobby_p">
                    {t(
                      "Sorry, there was an error with finding lobby, please try again"
                    )}
                  </p>
                  <button className="error_lobby_button">{t("Go back")}</button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <WarningModal
          tgId={tgId}
          onExit={onExit}
          userWhoConnect={userWhoConnect}
          userWhoMadeLobby={userWhoMadeLobby}
          setWarning={setWarning}
        />
      )}
    </>
  );
};
export default LoadingPage;
