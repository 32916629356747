import React from "react";
import logo2 from "../../images/logo2.svg";
import airplane from "../../images/airplane.png";
import { ClipLoader } from "react-spinners";

const MainLoading = () => {
  return (
    <div className="mainLoading">
      <img className="logoLoading" src={logo2} alt="Loading logo" />
      <ClipLoader className="mainLoading_spinner" color="#000" />
      <div className="airplane-container">
        <img id="airplane" src={airplane} className="airplane" alt="airplane" />
      </div>
    </div>
  );
};

export default MainLoading;
