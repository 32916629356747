import { useState, useEffect } from "react";

const useAchievementsData = (tgId, refreshUser) => {
  const [achievements, setAchievements] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  let userId;
  if (tgId === null) {
    userId = localStorage.getItem('tg_id');
  } else {
    userId = tgId;
  }

  // Fetch achievements
  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const initData = window.Telegram.WebApp.initData;
        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

        const encodedDataCheckString = encodeURIComponent(data_check_string);
        const url = `https://tg-back.itc-hub.ru/api/v1/userachievement?tg_id=${userId}`;
        
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'data-check-string': initData,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setAchievements(data.user_achievements);
      } catch (error) {
        console.error("Fetch error:", error);
        setError(error.message);
      }
    };

    fetchAchievements();
  }, [tgId, userId, refreshUser]);

  // Claim achievement
  const claimAchievement = async (achievementId) => {
    try {
        const initData = window.Telegram.WebApp.initData;
        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

        const encodedDataCheckString = encodeURIComponent(data_check_string);
      const url = `https://tg-back.itc-hub.ru/api/v1/claim_achievement?tg_id=${userId}&achievement_id=${achievementId}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'data-check-string': initData,
        },
      });

      const result = await response.json();

      if (response.ok) {
        setSuccessMessage(result.success);
      } else {
        setError(result.error);
      }
    } catch (error) {
      console.error("Claim error:", error);
      setError(error.message);
    }
  };

  return {
    achievements,
    error,
    successMessage,
    claimAchievement,
  };
};

export default useAchievementsData;
