import React, { useState, useEffect } from "react";
import womanHand from "../../../images/womanHand.png";
//import womanHand from "../../../images/womanHand1.svg";
import scissorsWom from "../../../images/scissorsWom.png";
import rockWom from "../../../images/rockWom.png";
import scissorsMan from "../../../images/scissorsMan.png";
import rockMan from "../../../images/rockMan.png";
import manHand from "../../../images/manHand.png";
//import manHand from "../../../images/manHand.svg";

const UserHand = ({
  selection,
  userData,
  userWin,
  userWinner,
  opponentWinner,
  players,
  tgId
}) => {
  const [gender, setGender] = useState(null);
  const [backgroundClass, setBackgroundClass] = useState("");

  const genderFromLocal = localStorage.getItem("genderUser");

  useEffect(() => {
    if (userData) {
      setGender(userData.gender);
    } /*if (players){
     const player= players.find((user) => user.tg_id === tgId);
     setGender(player.gender);
    }*/
  }, [userData, /*players*/]);

  useEffect(() => {
    console.log("gender:", gender);
  }, [gender]);

  useEffect(() => {
    if (userWin !== "") {
      if (userWin === "True") {
        setBackgroundClass("win-background");
      } if (userWin === "False")  {
        setBackgroundClass("lose-background");
      }
    }
  }, [userWin]);

  let handImage;
    if (genderFromLocal === true || gender === true) {
    if (selection === "rock") {
      handImage = rockMan;
    } else if (selection === "scissors") {
      handImage = scissorsMan;
    } else if (selection === "paper") {
      handImage = manHand;
    } else {
      handImage = manHand;
    }
  } else {
    if (selection === "rock") {
      handImage = rockWom;
    } else if (selection === "scissors") {
      handImage = scissorsWom;
    } else if (selection === "paper") {
      handImage = womanHand;
    } else {
      handImage = womanHand;
    }
  }

  const renderCoins = () => {
    const coins = [];
    for (let i = 0; i < 30; i++) {
      // Increase the number of coins
      const randomLeft = Math.random() * 100;
      const randomTop = Math.random() * 50; 
      coins.push(
        <div
          key={i}
          className={`coin ${userWinner === "True" ? "fall" : null}`}
          style={{ left: `${randomLeft}vw`, top: `${randomTop}vh` }}
        ></div>
      );
    }
    return coins;
  };
  return (
    <div className={`userHand ${backgroundClass}`}>
      <img
        className={`userHand__pic ${selection ? "animate" : ""}`}
        src={handImage}
        alt={selection}
      ></img>
      {userWinner !== "" && renderCoins()}
    </div>
  );
};

export default UserHand;
