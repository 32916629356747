import React, { useState } from "react";
import manPaper from "../../../images/manPaper.png";
import womanPaper from "../../../images/womanPaper.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUpdateUserData from "../../../hooks/useUpdateUserData";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const SexModal = ({ tgId }) => {
  const { t } = useTranslation();
  const [gender, setGender] = useState(true);
  const navigate = useNavigate();
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const {
    updateUserData,
    loading: updatingLoading,
    error: updatingError,
  } = useUpdateUserData();

  const handleGenderSelect = (newGender) => {
    impactOccurred("soft");
    setGender(newGender);
    updateUserData(tgId, newGender);
    setTimeout(() => {
      navigate("/main");
    }, 500);
  };

  return (
    <div className="modal__block_sex">
      <h3 className="modal__h">{t("Choose a gender:")}</h3>
      <div className="sex__block">
        <button
          className="sex__button man"
          style={{
            border: gender === true ? "2px solid rgba(181, 242, 49, 1)" : "none",
          }}
          onClick={() => handleGenderSelect(true)}
        >
          <div
            className="sex__item"
            style={{
              background: "#5B56FB",
            }}
          >
            <img className="sex__item_icon" src={manPaper} alt="Man" />
          </div>
          <p className="sex__item_text">{t("Man")}</p>
        </button>

        <button
          className="sex__button woman"
          style={{
            border: gender === false ? "2px solid rgba(181, 242, 49, 1)" : "none",
          }}
          onClick={() => handleGenderSelect(false)}
        >
          <div
            className="sex__item"
            style={{
              background: "#EDA3FD",
            }}
          >
            <img className="sex__item_icon" src={womanPaper} alt="Woman" />
          </div>
          <p className="sex__item_text">{t("Woman")}</p>
        </button>
      </div>
    </div>
  );
};

export default SexModal;
