import { useState, useEffect } from 'react';

const useTelegramDataVerification = () => {
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyData = async () => {
      try {
        const initData = window.Telegram.WebApp.initData;
        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

        const response = await fetch('/verify-telegram-data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data_check_string,
            hash_from_webapp
          })
        });

        const data = await response.json();

        if (data.isValid) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } catch (err) {
        setError(err);
      }
    };

    verifyData();
  }, []);

  return { isValid, error };
};

export default useTelegramDataVerification;