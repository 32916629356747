import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const UserPanel = ({ isReady, user, userData, roundCount, round, fetchedUser }) => {
  const { t } = useTranslation();
  const baseURL = "https://tg-back.itc-hub.ru";
  const [readyText, setReadyText] = useState("");
  const avatarImage = userData ? userData.avatar.image : fetchedUser ? fetchedUser.avatar?.image : null;
  const src = avatarImage ? `${baseURL}${avatarImage}` : null;
  useEffect(() => {
    if (userData && userData.gender !== undefined) {
      setReadyText(userData.gender ? (isReady ? t("ReadyM") : t("Not readyM")) : (isReady ? t("ReadyW") : t("Not readyW")));
    } else {
      setReadyText(isReady ? t("Ready") : t("Not ready"));
    }
  }, [userData, isReady, t]);

  return (
    <div className="userPanel">
      <div style={{ display: "flex" }}>
        <img
          className="userPanel__avatar"
          alt="User Avatar"
          src={src}
        />
        <div className="userPanel__name_block">
          <p className="userPanel__name">
            {userData ? userData.username : fetchedUser ? fetchedUser.username : t("Player name")}
          </p>
          <div className={`userPanel__isReady ${isReady ? "ready" : ""}`}>
            {readyText}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <p className="win__status">{t("Wins")}</p>
        <div className="win__status_number">
          {round}/{roundCount}
        </div>
      </div>
    </div>
  );
};

export default UserPanel;