import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const OpponentPanel = ({ isReady, opponentData, roundCount, opponentRound, opponentReady }) => {
  const { t } = useTranslation();
  const baseURL = "https://tg-back.itc-hub.ru";
  const [readyText, setReadyText] = useState("");

  useEffect(() => {
    if (opponentData && opponentData.gender !== undefined) {
      setReadyText(opponentData.gender ? ((isReady || opponentReady) ? t("ReadyM") : t("Not readyM")) : ((isReady || opponentReady) ? t("ReadyW") : t("Not readyW")));
    } else {
      setReadyText((isReady || opponentReady) ? t("ReadyW") : t("Not readyW"));
    }
  
  }, [opponentData, isReady, t, opponentReady]);

  return (
    <div className="opponentPanel">
      <div style={{ display: "flex" }}>
        <img
          className="userPanel__avatar"
          src={`${baseURL}${opponentData ? opponentData.avatar.image : null}`}
          alt="Opponent Avatar"
        />
        <div className="userPanel__name_block">
          <p className="userPanel__name">
            {opponentData ? opponentData.username : t("Player name")}
          </p>
          <div className={`userPanel__isReady ${(isReady || opponentReady) ? "ready" : ""}`}>
            {readyText}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <p className="win__status">{t("Wins")}</p>
        <div className="win__status_number">
          {opponentRound}/{roundCount}
        </div>
      </div>
    </div>
  );
};

export default OpponentPanel;