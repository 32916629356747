import { useState } from 'react';

const usePostCards = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const postCards = async (tg_id, id_custom) => {
    setLoading(true);
    setError(null);

    const initData = window.Telegram.WebApp.initData;
    const pairs = initData.split('&').map(pair => pair.split('='));
    pairs.sort((a, b) => a[0].localeCompare(b[0]));
    const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
    const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

    const body = JSON.stringify({
      tg_id,
      id_custom,
    });

    try {
      const encodedDataCheckString = encodeURIComponent(data_check_string);

      const res = await fetch('https://tg-back.itc-hub.ru/api/v1/cards', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'data-check-string': initData,
        },
        body: body,
      });

      const data = await res.json();
      setResponse(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { postCards, response, error, loading };
};

export default usePostCards;