import React, { useState, useEffect } from "react";
import nextWhite from "../../../images/nextWhite.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const ExitModal = ({ onExit, tgId, showExitModalOnReload }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [gameOver, setGameOver] = useState(false);
  const [impactOccurred, notificationOccurred, selectionChanged] =
  useHapticFeedback();
  const handleExit = () => {
    impactOccurred("soft");
    const initData = window.Telegram.WebApp.initData;
    onExit({
      "action": "exit",
      "user_id": tgId,
      "data-check-string": initData,
   
    });
    
    setGameOver(true)
    navigate("/main", { state: { gameOver} });
    const pageElement = document.querySelector(".page");

    pageElement.style.backgroundColor = "#FFFBF9";
    //window.location.reload();
    sessionStorage.setItem("wasReload", "true");

    //sessionStorage.removeItem('wasReload');

  };

  return (
    <div className="modal__block" style={{ width: "310px", alignItems: "center", gap: "15px" }}>
      {!showExitModalOnReload && (
        <>
      <h3 className="exitModal__h">{t('Are you sure you want to leave the game?')}</h3>
      <p className="exitModal__p">{t('If you exit, it will be counted as a loss.')}</p>
      </>
      )}
        {showExitModalOnReload && (
        <>
      <h3 className="exitModal__h">{t('You have reloaded the page :(')}</h3>
      <p className="exitModal__p">{t('The progress of the game has been reset.')}</p>
      </>
      )}
      <button className="exitModal__button" onClick={handleExit}>
        <p className="exitModal__button_p">{t('Leave the game')}</p>
        <img className="exitModal__button_icon" src={nextWhite} alt="Leave game" />
      </button>
    </div>
  );
};

export default ExitModal;
