import React, { useState, useEffect } from "react";
import next1 from "../../../images/next1.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingPage from "../../loadingPage/loadingPage";
import useUserGet from "../../../hooks/useUserGet";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const LobbyModal = ({ onClose, tgId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [betAmount, setBetAmount] = useState("");
  const [rounds, setRounds] = useState(1);
  const [betAmountError, setBetAmountError] = useState(false);
  const [balanceError, setBalanceError] = useState(false);
  const [lobbyData, setLobbyData] = useState(null);
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lobbyOverall, setLobbyOverall] = useState(null);
  const { user: fetchedUser } = useUserGet(tgId);
  const [waiting, setWaiting] = useState(false);
  const [goGame, setGoGame] = useState(false);
  const [userExit, setUserExit] = useState(false);
  const [ownerExit, setOwnerExit] = useState(false);
  const [noConfirm, setNoConfirm] = useState(false);
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  useEffect(() => {
    const ws = new WebSocket("wss://tg-back.itc-hub.ru/ws/game_search/");

    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.lobby_info) {
        console.log("Received message:", message);
        if (message.message === "New user has joined the lobby") {
          const lobbyMessage = message;
          setLobbyData(lobbyMessage);
          console.log(lobbyMessage);
        }
      }
      if (message.message === "Waiting for the other user to confirm") {
        setWaiting(true);
        console.log(message.message);
      }
      if (message.message === "Waiting for the other user to confirm") {
        setNoConfirm(true);
      }
      if (message.message === "Game started") {
        setGoGame(true);
        setNoConfirm(false);
        console.log(message.message);
      }

      if (message.message === "User exit lobby") {
        const owner = message.lobby_owner;
        const exitUser = message.user;
        if (exitUser !== tgId && owner === false) {
          setUserExit(true);
        }
        if (exitUser !== tgId && owner === true) {
          setOwnerExit(true);
        }
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(ws);
  }, []);

  const handleCreateLobby = (lobby) => {
    setLoading(true);
    const initData = window.Telegram.WebApp.initData;

    if (socket && socket.readyState === WebSocket.OPEN) {
      const data = {
        action: "create_lobby",
        stake: lobby.betAmount,
        max_wins: lobby.rounds,
        user_id: tgId,
        "data-check-string": initData,
      };
      socket.send(JSON.stringify(data));
      console.log(JSON.stringify(data));
      setLobbyOverall(data);
      console.log(loading);
    }
  };

  const handleConfirm = (params) => {
    console.log("Received gameParams:", params);

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
      console.log("gameparams", params);
    }
  };

  const handleExitNoConnection = (params) => {
    console.log("Received gameParams:", params);

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
      console.log("gameparams", params);
    }
  };

  const handleExit = (params) => {
    console.log("Received gameParams:", params);

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
      console.log("gameparams", params);
    }
  };

  const handleBetChange = (e) => {
    const value = e.target.value;
    setBetAmount(value);

    if (!/^\d+$/.test(value) || parseInt(value, 10) < 100) {
      setBetAmountError(true);
      setBalanceError(false);
    } else if (parseInt(value, 10) % 10 !== 0) {
      setBetAmountError(true);
      setBalanceError(false);
    } else if (fetchedUser && parseInt(value, 10) > fetchedUser.balance) {
      setBetAmountError(false);
      setBalanceError(true);
    } else {
      setBetAmountError(false);
      setBalanceError(false);
    }
  };
  const handleRoundChange = (value) => {
    setRounds(value);
  };

  const handleFindOpponent = () => {
    if (betAmount && !betAmountError && !balanceError) {
      const lobby = {
        betAmount: parseInt(betAmount, 10),
        rounds,
      };
      impactOccurred("soft");
      handleCreateLobby(lobby);
      // onClose();
    } else {
      setBetAmountError(true);
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="modal__block_betModal">
            <div className="lobby__bet_block">
              <div className="block__input">
                <label className="lobby__bet_label">{t("Bet")}:</label>
                {betAmountError && (
                  <p className="error-text">
                    {parseInt(betAmount, 10) < 100
                      ? t("min: 100")
                      : t("Bet must be a multiple of 10")}
                  </p>
                )}
                {balanceError && (
                  <p className="error-text" style={{ color: "red" }}>
                    {t("Bet exceeds your balance")}
                  </p>
                )}
              </div>
              <input
                className={`lobby__bet_input ${
                  betAmountError || balanceError ? "input-error" : ""
                }`}
                value={betAmount}
                onChange={handleBetChange}
              />
            </div>

            <div className="win__block">
              <label className="win__block_label">{t("Best of:")}</label>
              <div className="win__block_checkboxes">
                {[1, 3, 5].map((num) => (
                  <button
                    key={num}
                    className={`win__button ${
                      rounds === num ? "active selected" : ""
                    }`}
                    onClick={() => handleRoundChange(num)}
                  >
                    {num}
                  </button>
                ))}
              </div>
            </div>

            <button
              className="play__button_lobby"
              style={{ width: "320px", marginTop: "5px" }}
              onClick={handleFindOpponent}
              disabled={betAmountError || balanceError}
            >
              <p className="play__button_p">{t("Create lobby")}</p>
              <img className="play__button_icon" src={next1} alt="next icon" />
            </button>
          </div>
        </>
      ) : (
        <LoadingPage
          lobbyData={lobbyData}
          lobbyOverall={lobbyOverall}
          tgId={tgId}
          onConfirm={handleConfirm}
          onExit={handleExit}
          waiting={waiting}
          goGame={goGame}
          setUserExit={setUserExit}
          userExit={userExit}
          ownerExit={ownerExit}
          setOwnerExit={setOwnerExit}
          onExitNoConnection={handleExitNoConnection}
          socket={socket}
          noConfirm={noConfirm}
          setNoConfirm={setNoConfirm}
        />
      )}
    </>
  );
};

export default LobbyModal;
