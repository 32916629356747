import { useState, useEffect } from "react";

const useLobbyData = (sortBy, sortDirection, tgId) => {
  const [lobbyData, setLobbyData] = useState([]);

  let userId;
  if (tgId === null) {
    userId = localStorage.getItem('tg_id');
  } else {
    userId = tgId;
  }

  useEffect(() => {
    const fetchLobbyData = async () => {
      try {
        const sortField = sortBy === "stake" ? "stake" : "max_wins";
        const sortOrder = sortDirection === "asc" ? "asc" : "desc";
        const url = `https://tg-back.itc-hub.ru/api/v1/lobbies?sort_field=${sortField}&sort_order=${sortOrder}&tg_id=${userId}`;

        const initData = window.Telegram.WebApp.initData;
        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

        const encodedDataCheckString = encodeURIComponent(data_check_string);

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'data-check-string': initData,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setLobbyData(data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchLobbyData();

    return () => {
    };
  }, [sortBy, sortDirection, tgId, userId]);

  return lobbyData;
};

export default useLobbyData;