import React, { useState, useEffect } from "react";
import close from "../../../images/close.png";
import copy from "../../../images/CopyWhite.svg";
import invite from "../../../images/send.svg";
import coin3 from "../../../images/coin3.svg";
import { useTranslation } from "react-i18next";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import backButton1 from "../../../images/backButton1.svg";
import BonusesModal from "../bonuses/bonuses";
import SettingsModal from "../settingsModal/settingsModal";
import gift from "../../../images/Gift.png";
import closeIcon from "../../../images/CloseModal.svg";
import CopyCode from "../codes/codes";
import useGetRef from "../../../hooks/refs/useGetRef";

const Referrals = ({
  onClose,
  user,
  tgId,
  fetchedUser,
  baseURL,
  userAvatar,
  setBonusesModalOpen,
  isBonusesModalOpen,
  loading,
  handleOpenAvatarModal,
  truncateString,
  formatBalance,
  userBalance,
  showSettings,
  handleCloseSettings,
  onGenderChange,
  setGenderChanged,
  genderChanged,
  handleOpenSettings,
  settings,
}) => {
  const [impactOccurred] = useHapticFeedback();
  const { t } = useTranslation();
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [inviteClicked, setInviteClicked] = useState(false);
  const [refCodeError, setRefCodeError] = useState(null);
  const [isRefCodeFetched, setIsRefCodeFetched] = useState(false);

  const { refCode, loading: refLoading, error: refError, fetchRefCode } = useGetRef({
    tgId,
  });

  const testLink = "https://t.me/dshgfgweqbot";

  const handleInviteClick = () => {
    impactOccurred("soft");

    setInviteClicked(true);
    fetchRefCode();
  };

  useEffect(() => {
    if (refError) {
      if (refError === "no referral codes") {
        setRefCodeError("no referral codes");
      }
    } else if (inviteClicked && refCode) {
      const message = `Привет! Тут будет какая-то эсэмэска. Вот твой код - ${refCode}`;
      const url = encodeURIComponent(testLink);
      const tgLink = `https://t.me/share?url=${url}&text=${encodeURIComponent(message)}`;

      window.Telegram.WebApp.openTelegramLink(tgLink);

      setInviteClicked(false);
      setIsRefCodeFetched(true);
    }
  }, [inviteClicked, refCode, refError]);

  const handleOpenCopyModal = () => {
    impactOccurred("soft");
    setIsCopyModalOpen(true);
    const pageElement = document.querySelector(".modal__overlay");
    pageElement.style.backgroundColor = "rgb(45 45 45 / 96%)";
  };

  const handleCloseCopyModal = () => {
    setIsCopyModalOpen(false);
  };

  const openMoreBonuses = () => {
    impactOccurred("soft");
    setBonusesModalOpen(true);
  };

  const closeBonusesModal = () => {
    setBonusesModalOpen(false);
  };

  return (
    <>
      <div className="daily__modal_buttons">
        <button className="backButton_daily" onClick={onClose}>
          <img className="backButton_daily_icon" src={backButton1} alt="Back" />
        </button>
        <>
          {loading ? (
            <div
              className="user__header"
              style={{
                minHeight: "50px",
                maxHeight: "50px",
                minWidth: "220px",
                maxWidth: "220px",
                padding: 0,
                boxShadow: "0px 0px 5px 1px #b5f231",
              }}
            >
              <div className="user__block"></div>
            </div>
          ) : (
            <header
              className="user__header"
              style={{
                minWidth: "220px",
                maxWidth: "220px",
                padding: "9px 12px",
                height: "unset",
              }}
            >
              <div className="user__block" onClick={handleOpenAvatarModal}>
                <img
                  className="user__avatar"
                  src={userAvatar || `${baseURL}${fetchedUser?.avatar?.image}`}
                  alt="Avatar"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 5px 1px #b5f231",
                  }}
                />
                <p className="user__name" style={{ fontSize: "12px" }}>
                  {truncateString(user.username || fetchedUser.username, 25)}
                </p>
              </div>
              <div className="balance__block">
                <img
                  className="balance__icon"
                  src={coin3}
                  alt="Coin icon"
                  style={{ width: "14.42px", height: "14.42px" }}
                />
                <p className="balance__amount" style={{ fontSize: "12px" }}>
                  {formatBalance(userBalance)}
                </p>
              </div>
            </header>
          )}
        </>
        {showSettings && (
          <SettingsModal
            onClose={handleCloseSettings}
            onGenderChange={onGenderChange}
            user={user}
            tgId={tgId}
            fetchedUser={fetchedUser}
            setGenderChanged={setGenderChanged}
            genderChanged={genderChanged}
          />
        )}

        <button className="settings__button" onClick={handleOpenSettings}>
          <img
            className="settings__button_icon"
            src={settings}
            alt="Settings"
          />
        </button>
      </div>
      <div className="ref__text-block">
        <h3 className="ref__text_h">{t("INVITE YOUR FRIENDS")}</h3>
        <p className="ref__text_p">
          {t("You and your friend will receive bonuses")}
        </p>
      </div>
      <div className="ref__white-box">
        <div className="ref__card">
          <img className="ref__card_avatar" src={gift} alt="Avatar" />
          <div className="ref__card_block">
            <p className="ref__card_title">{t("Invite a friend")}</p>
            <div className="ref__card_text">
              <img className="ref__coin-icon" src={coin3} />
              <p className="ref__card_p">
                {t("+2 500 for you and your friend")}
              </p>
            </div>
          </div>
        </div>
        <button
          className="bonuses"
          onClick={openMoreBonuses}
        >
          {t("More bonuses")}
        </button>

        <p className="ref__counter">0/10 {t("friends")}</p>

        <div className="refs__container">
          {[...Array(3)].map((_, i) => (
            <div className="refs__friend" key={i}>
              <div className="friend__block">
                <img className="refs__avatar" alt="Avatar" />
                <p className="refs__friend_name">User {i + 1}</p>
              </div>
              <div className="bonuse__block">
                <img className="bonuse__icon" src={coin3} />
                <p className="refs__friend_money">+100</p>
              </div>
            </div>
          ))}
        </div>
        <div className="invite__box">
          <button
            className={`invite__button ${refCodeError ? 'disabled' : ''}`}
            onClick={handleInviteClick}
            disabled={!!refCodeError}
          >
            <p className="invite__button_text">{t("Invite a friend")}</p>
            <img className="invite__button_icon" src={invite} />
          </button>

          <button className="copy__button" onClick={handleOpenCopyModal}>
            <img className="copy__button_icon" src={copy} />
          </button>
        </div>
      </div>

      {isBonusesModalOpen && (
        <div className="overlay">
          <BonusesModal onClose={closeBonusesModal} />
        </div>
      )}
      {isCopyModalOpen && (
        <div className="overlay">
          <CopyCode onClose={handleCloseCopyModal} tgId={tgId}/>
        </div>
      )}
    </>
  );
};

export default Referrals;
