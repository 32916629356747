import React, { useState, useEffect } from "react";
import pocket from "../../images/pocket2.svg";
import pay from "../../images/pay2.svg";
import logo2 from "../../images/logo2.svg"

const VipPass = () => {
  return (
    <>
      <img className="vip_logotype" src={logo2}/>
      <form className="vip_form">
      <div className="vip_text_box">

        <h3 className="vip_h">Никнейм</h3>
        <p className="vip_text">
          По каким-то причинам у вас нет приглашения, мы можем оформить вам VIP
          пропуск за небольшую сумму
        </p>
      </div>
    
        <input
          className="vip_form_input"
          placeholder="Стоимость VIP пропуска - 3 TON"
        ></input>
        <button className="vip_form_button">
          <p className="vip_form_button_p">Подключить кошелек</p>
          <img className="vip_form_button_icon" src={pocket} />
        </button>
      </form>
    </>
  );
};

export default VipPass;
