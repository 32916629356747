import React, { useState, useEffect } from "react";
import coin3 from "../../images/coin3.svg";
import DailyModal from "../modals/dailyModal/dailyModal";
import useUserGet from "../../hooks/useUserGet";
import useFetchAvatars from "../../hooks/useFetchAvatars";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

const UserHeader = ({ user, tgId, handleAvatarChanged, refreshUser, setRefreshUser, setGenderChanged, genderChanged, gameOver }) => {
  const [showSettings, setShowSettings] = useState(false);
  const [userAvatar, setUserAvatar] = useState();
  const [userBalance, setUserBalance] = useState();
  const [showFullBalance, setShowFullBalance] = useState(false);
  const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();

  const { user: fetchedUser, error: userError, getUser } = useUserGet(tgId, refreshUser, genderChanged, gameOver);  
  const { avatars, error } = useFetchAvatars();

  const [loading, setLoading] = useState(true);

  const baseURL = "https://tg-back.itc-hub.ru";

  useEffect(() => {
    if (fetchedUser && fetchedUser.avatar) {
      const fullAvatarUrl = `${baseURL}${fetchedUser.avatar.image}`;
      setUserAvatar(fullAvatarUrl);
      setUserBalance(fetchedUser.balance);
      localStorage.setItem("balance", fetchedUser.balance);
      localStorage.setItem("username",  fetchedUser.username);
    }
  }, [fetchedUser]);

  useEffect(() => {
    if (userAvatar) {
      setLoading(false);
    }
  }, [userAvatar]);

  useEffect(() => {
    const avatarFromLocal = localStorage.getItem("avatar");
    if (!avatarFromLocal && fetchedUser && fetchedUser.avatar) {
      const fullAvatarUrl = `${baseURL}${fetchedUser.avatar.image}`;
      localStorage.setItem("avatar", fullAvatarUrl);
    }
  }, [fetchedUser]);

  const truncateString = (str, maxLength) => {
    str = String(str);
    if (str.length <= maxLength) {
        return str;
    }
    return str.slice(0, maxLength) + "...";
};

  const handleOpenSettings = (e) => {
    e.stopPropagation();

    const tg = window.Telegram.WebApp;
    tg.setHeaderColor("rgba(72, 72, 71)");
    impactOccurred("soft");
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    const tg = window.Telegram.WebApp;
    tg.setHeaderColor("#fffbf9");
    setShowSettings(false);
  };

  const handleAvatarChange = (newAvatar) => {
    setUserAvatar(newAvatar);
    localStorage.setItem("avatar", newAvatar);
    handleAvatarChanged(newAvatar);
  };

  const avatarFromLocal = localStorage.getItem("avatar");
  const userFromLocal = localStorage.getItem("username");

  const balanceFromLocal = localStorage.getItem("balance");
  const formatBalance = (balance) => {
    if (balance < 10000000) {
      return balance;
    }
    const millionBalance = (balance / 1000000).toFixed(1);
    return `${millionBalance}M`;
  };

  const handleBalanceClick = () => {
    impactOccurred("soft");
    setShowFullBalance(!showFullBalance);
  };

  return (
    <div>
      <header className="user__header">
        <div className="user__block" onClick={handleOpenSettings}   style={{ outline: 'none', userSelect: "none" }}
>
          <img
            className="user__avatar"
            src={avatarFromLocal || userAvatar || `${baseURL}${fetchedUser?.avatar?.image}`}
            alt="Avatar"
            style={{boxShadow: "rgb(181, 242, 49) 0px 0px 5px 1px",     borderRadius: "10px"}}
          />
          <p className="user__name">
            {truncateString(userFromLocal || fetchedUser.username, 25)}
          </p>
        </div>
        <div className="balance__block" onClick={handleBalanceClick}>
          <img className="balance__icon" src={coin3} alt="Coin icon" />
          <p className="balance__amount">
            {formatBalance( balanceFromLocal || userBalance)}
          </p>
          {showFullBalance && (
            <>
              <div className="balance__popup">
                <div className="balance__popup_triangle"/>
                <div className="balance__popup_content">
                  <p className="popup_balance_p"> {userBalance ? userBalance : "0"}</p>
                  <img className="popup_balance_icon" src={coin3}/>
                </div>
              </div>
            </>
          )}
        </div>
      </header>
      {showSettings && (
        <DailyModal
          onAvatarSelect={handleAvatarChange}
          fetchedUser={fetchedUser}
          tgId={tgId}
          onClose={handleCloseSettings}
          user={user}
          refreshUser={refreshUser} 
          setRefreshUser={setRefreshUser}
          setGenderChanged={setGenderChanged}
          genderChanged={genderChanged}
         
        />
      )}
    </div>
  );
};

export default UserHeader;