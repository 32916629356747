import React, { useEffect, useState } from "react";
import star4 from "../../../images/star4.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSendUserData from "../../../hooks/useSendUserData";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const HiModal = ({ tgId, username }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendUserData, error } = useSendUserData();
  const [extraName, setExtraName] = useState("Null");
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();

    
  useEffect(() => {
    if (username === undefined) {
      sendUserData(tgId, extraName);
    }
    if (tgId && username) {
      sendUserData(tgId, username);
    }
  }, [tgId, username, sendUserData]);

  useEffect(() => {
    if (error) {
    }
  }, [error]);

  const handleChooseSex = () => {
    impactOccurred("soft");
    navigate("/gender");
  };

  return (
    <div className="modal__block">
      <div className="modal__block_text">
        <h3 className="modal__h" style={{ marginBottom: "20px" }}>
          {t("Welcome!")}
        </h3>
        <p className="modal__p">
          {t(
            "While you were entering the invite code, we took care of everything! You can go play right now, because you already have 1000 tokens on your balance."
          )}
        </p>
        <p className="modal__p"> {t("Fight, and may luck follow you.")}</p>
      </div>
      <button
        className={`modal__button ${error ? "modal__button_disabled" : ""}`}
        onClick={handleChooseSex}
        disabled={error}
      >
        <p className="modal__button_p">{t("To the game")}</p>
        <img className="modal__button_icon" src={star4} alt="star" />
      </button>
      {error && (
        <>
          <div className="error-message_block">
            <p className="error-message">{t("Oops, there was an error :(")}</p>
            <p className="error-message">{t("You might not send the key.")}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default HiModal;
