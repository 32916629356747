import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import closeIcon from "../../../images/CloseModal.png";
import copy from "../../../images/Copy.svg";
import useGetRefs from "../../../hooks/refs/useGetRefs";

const CopyCode = ({ onClose, tgId }) => {
  const { t } = useTranslation();
  const { refCodes, loading: refsLoading, error: refsError } = useGetRefs(tgId);
  const [copiedCodes, setCopiedCodes] = useState([]);
  const [refCodesFetched, setRefCodesFetched] = useState([]); 
  const [noCodes, setNoCodes] = useState(true); 
  const [allCodesUsed, setAllCodesUsed] = useState(false); // Добавляем состояние для отслеживания, использованы ли все коды

  useEffect(() => {
    if (refCodes && refCodes.length > 0) {
      setRefCodesFetched(refCodes);
      setNoCodes(false);
      setAllCodesUsed(refCodes.every(code => code.used_code)); // Проверяем, использованы ли все коды
    } else {
      setNoCodes(true);
      setAllCodesUsed(false);
    }
  }, [refCodes]);

  const copyAllCodes = () => {
    const codesToCopy = refCodes.map(code => code.code).join('\n');
    const message = `${t("Ваши реф-коды на сегодня:")}\n${codesToCopy}`;
    navigator.clipboard.writeText(message);
    setCopiedCodes(refCodes.map(code => code.code));
    alert(t('Код скопирован в буфер обмена!'));
  }
  const copyCurrentCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopiedCodes([...copiedCodes, code]);
    alert(t('Код скопирован в буфер обмена!'));
  }

  const getCodeStyle = (code) => {
    return code.used_code ? { border: "2px solid red" } : { border: "2px solid #9df23b;" };
  }

  return (
    <div className="modal__overlay_settings" onClick={onClose}>
      <div className="copy-modal" onClick={e => e.stopPropagation()}>
        <div className="copy-modal-content">
          <div className="copy-header">
            <h2>0 / 10 {t("referrals")}</h2>
            <button className="close-button" onClick={onClose}>
              <img className="close-button_icon" src={closeIcon} alt="Close" />
            </button>
          </div>
          <div className="codes-table">
            {refCodesFetched.map((code, index) => (
              <div
                key={index}
                className="code-block"
                style={getCodeStyle(code)}
              >
                <p className="code_p"> {code.code.length > 8 ? `${code.code.slice(0, 8)}...` : code.code}</p>
                <button className="copyButton" onClick={() => copyCurrentCode(code.code)}>
                  <img className="code_icon" src={copy} alt="Copy" />
                </button>
              </div>
            ))}
          </div>
          <button
            className="invite__button"
            style={{
              animation: "none",
              marginTop: "5px",
              justifyContent: "center",
              width: "100%",
              backgroundColor: noCodes || allCodesUsed ? "gray" : "#5b56fb", // Устанавливаем серый цвет, если noCodes === true или все коды использованы
              cursor: noCodes || allCodesUsed ? "not-allowed" : "pointer", // Устанавливаем курсор "не разрешено", если noCodes === true или все коды использованы
            }}
            onClick={copyAllCodes}
            disabled={noCodes || allCodesUsed} // Блокируем кнопку, если noCodes === true или все коды использованы
          >
            <p className="invite__button_text">{t("COPY ALL CODES")}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CopyCode;