import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

export const LanguageProvider = ({ children, initialLanguage }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(initialLanguage || i18n.language);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (initialLanguage) {
      setLanguage(initialLanguage);
      i18n.changeLanguage(initialLanguage);
    } else {
      const savedLanguage = localStorage.getItem('i18nextLng');
      if (savedLanguage) {
        setLanguage(savedLanguage);
        i18n.changeLanguage(savedLanguage);
      }
    }
  }, [initialLanguage, i18n]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
