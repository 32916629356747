import { useState, useEffect } from "react";

const useFetchAvatars = () => {
  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const initData = window.Telegram.WebApp.initData;
        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));

        const response = await fetch("https://tg-back.itc-hub.ru/api/v1/avatars", {
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            'data-check-string': initData,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAvatars(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvatars();
  }, []);

  return { avatars, loading, error };
};

export default useFetchAvatars;