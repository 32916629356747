import { useState, useCallback } from 'react';

const useUpdateUserData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateUserData = useCallback(async (tg_id, gender) => {
    const url = 'https://tg-back.itc-hub.ru/api/v1/user';
    const initData = window.Telegram.WebApp.initData;
    const pairs = initData.split('&').map(pair => pair.split('='));
    pairs.sort((a, b) => a[0].localeCompare(b[0]));
    const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
    const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

    const body = JSON.stringify({
      tg_id,
      gender,
    });


    const encodedDataCheckString = encodeURIComponent(data_check_string);
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'data-check-string': initData,
        },
        body: body,
      });


      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error('Error:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { updateUserData, loading, error };
};

export default useUpdateUserData;