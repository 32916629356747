import React, { useEffect, useState } from "react";
import warningButton from "../../../images/warningButton.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useLocation } from 'react-router-dom';

const WarningModal = ({userWhoConnect,userWhoMadeLobby, tgId, onExit, setWarning }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [lobbyOwner, setLobbyOwner] = useState(0);
  const [lobbyId, setLobbyId] = useState(null);
  const [gameOver, setGameOver] = useState(true);
  const location = useLocation();

  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const handleGoBack = () => {
    setWarning(false);
  };

  useEffect(() => {
    if (userWhoMadeLobby) {
    setLobbyOwner(1);
    setLobbyId(userWhoMadeLobby.id)
    } if (userWhoConnect){
      setLobbyId(userWhoConnect.id)
      setLobbyOwner(0);
    }
  }, [userWhoConnect, userWhoMadeLobby]);

  const handleGoMain = () => {
    const initData = window.Telegram.WebApp.initData;

    impactOccurred("soft");
    onExit({
      "action": "lobby_exit",
      "lobby_id": lobbyId,
      "user_id": tgId,
      "lobby_owner": lobbyOwner,
      "data-check-string": initData,

    })
    navigate("/main", { state: { gameOver} });
    if (location.pathname === '/main') {
      window.location.reload();
    }
  };

  return (
    <div className="overlay_warning">
      <div className="modal__content">
        <div className="modal__block" style={{ width: "310px", alignItems: "center" }}>
          <h3 className="warningModal__h">{t("Warning!")}</h3>
          <p className="warningModal__p">
            {t("If you reject the game, we will have to suspend you from games for 5 minutes.")}
          </p>
          <div className="warningModal__buttons">
            <button className="warningModal__button" onClick={handleGoMain}>
              <p className="warningModal__button_p">{t("Reject")}</p>
              <img className="warningModal__button_icon" src={warningButton} alt="warning button" />
            </button>
            <button className="warningModal__button" onClick={handleGoBack}>
              <p className="warningModal__button_p">{t("Cancel")}</p>
              <img className="warningModal__button_icon" src={warningButton} alt="warning button" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
