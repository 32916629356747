import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchAvatars from "../../../hooks/useFetchAvatars";
import backButton1 from "../../../images/backButton1.svg";
import coin3 from "../../../images/coin3.svg";
import useSelectAvatar from "../../../hooks/useSelectAvatar";
import useUserGet from "../../../hooks/useUserGet";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const AvatarModal = ({ onClose, onAvatarSelect, avatarSelect, user, tgId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userBalance, setUserBalance] = useState();
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [impactOccurred, notificationOccurred, selectionChanged] =
  useHapticFeedback();
  const {
    user: fetchedUser,
    loading: userLoading,
    error: userError,
  } = useUserGet(tgId);

  const { avatars, loading, error } = useFetchAvatars();
  const {
    selectAvatar,
    loading: selectLoading,
    error: selectError,
  } = useSelectAvatar();

  useEffect(() => {
    if (fetchedUser && fetchedUser.avatar) {
      const fullAvatarUrl = `https://tg-back.itc-hub.ru${fetchedUser.avatar.image}`;
      setSelectedAvatar(fullAvatarUrl);
      setUserBalance(fetchedUser.balance);
    }
  }, [fetchedUser]);


  useEffect(() => {
    console.log(fetchedUser.gender);

  }, [fetchedUser.gender]);

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  };
  
  const formatBalance = (balance) => {
    if (balance < 10000000) {
      return balance;
    }
    const millionBalance = (balance / 1000000).toFixed(1);
    return `${millionBalance}M`;
  };

  const handleAvatarSelect = (avatar) => {
    impactOccurred("soft");
    const baseURL = "https://tg-back.itc-hub.ru";
    const fullAvatarUrl = `${baseURL}${avatar.image}`;
    setSelectedAvatar(fullAvatarUrl);
    onAvatarSelect(fullAvatarUrl);
    avatarSelect(fullAvatarUrl);
    selectAvatar(tgId, avatar.id); 
  };

  if (loading) return <p>{t('Loading avatars')}...</p>;
  if (error) return <p>{t('Error loading avatars')}: {error.message}</p>;

  const renderAvatars = () => {
    const menAvatars = avatars.men || [];
    const womenAvatars = avatars.women || [];

    const getAvatarUrl = (image) => {
      const baseURL = "https://tg-back.itc-hub.ru";
      return `${baseURL}${image}`;
    };

    if (fetchedUser.gender) {
      return (
        <>
          {menAvatars.map((avatar) => (
            <img
              key={avatar.id}
              className={`setting__item_avatar ${selectedAvatar === getAvatarUrl(avatar.image) ? "selected" : ""}`}
              alt={`${t('Avatar')} ${avatar.id}`}
              src={getAvatarUrl(avatar.image)}
              style={{ opacity: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                handleAvatarSelect(avatar);
              }}
            />
          ))}
          {womenAvatars.map((avatar) => (
            <img
              key={avatar.id}
              className={`setting__item_avatar ${selectedAvatar === getAvatarUrl(avatar.image) ? "selected" : ""}`}
              alt={`${t('Avatar')} ${avatar.id}`}
              src={getAvatarUrl(avatar.image)}
              style={{ opacity: 0.4 }}
            />
          ))}
        </>
      );
    } else {
      return (
        <>
          {womenAvatars.map((avatar) => (
            <img
              key={avatar.id}
              className={`setting__item_avatar ${selectedAvatar === getAvatarUrl(avatar.image) ? "selected" : ""}`}
              alt={`${t('Avatar')} ${avatar.id}`}
              src={getAvatarUrl(avatar.image)}
              style={{ opacity: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                handleAvatarSelect(avatar);
              }}
            />
          ))}
          {menAvatars.map((avatar) => (
            <img
              key={avatar.id}
              className={`setting__item_avatar ${selectedAvatar === getAvatarUrl(avatar.image) ? "selected" : ""}`}
              alt={`${t('Avatar')} ${avatar.id}`}
              src={getAvatarUrl(avatar.image)}
              style={{ opacity: 0.4 }}
            />
          ))}
        </>
      );
    }
  };

  return (
    <div className="modal__overlay_settings" onClick={onClose}>
      <div className="modal__content_settings" onClick={handleClick}>
        <div className="modal__block_settings">
          <div className="settingsModal__box" style={{ gap: "6px" }}>
            <button className="backButton_avatar" onClick={onClose}>
              <img
                className="backButton_settings_icon"
                src={backButton1}
                alt={t('Back')}
              />
            </button>
            <h3 className="settingsModal__h">{t('AVATARS')}</h3>
            <header
              className="user__header"
              style={{
                width: "201px",
                padding: "9px 12px",
                height: "unset",
                boxShadow: "0 0 15px 2px #81808026",
              }}
            >
              <div className="user__block">
                <img
                  className="user__avatar"
                  src={selectedAvatar}
                  alt={t('Avatar')}
                  style={{ width: "32px", height: "32px" }}
                />
                <p className="user__name" style={{ fontSize: "9px" }}>
                {truncateString(((user.username || fetchedUser.username) ? user.username || fetchedUser.username : "User" ), 25)}
                </p>
              </div>
              <div className="balance__block">
                <img
                  className="balance__icon"
                  src={coin3}
                  alt={t('Coin icon')}
                  style={{ width: "12.42px", height: "12.42px" }}
                />
                <p className="balance__amount" style={{ fontSize: "9px" }}>
                  {formatBalance(userBalance)}
                </p>
              </div>
            </header>
            <div
              className="setting__item"
              style={{
                width: "201px",
                padding: "9px 12px",
                flexDirection: "column",
                alignItems: "start",
                gap: "2px",
                justifyContent: "normal",
                height: "99px",
              }}
            >
              <p className="setting__item_p_avatar">{t('GENDER')}</p>
              <div className="setting__item_avatars">
                {renderAvatars()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarModal;
