import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../../images/arrow1.svg";
import russian from "../../images/russian.pdf";
import british from "../../images/british.pdf";
import next from "../../images/next1.svg";
import vip from "../../images/vip1.svg";
import logo1 from "../../images/logo1.png";
import logo2 from "../../images/logo2.svg";
const PassPage = ({}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState("russian");
  const navigate = useNavigate();

  const handleGoVip = () => {
    navigate("/vip");
  };
  const handleGoMain = () => {
    navigate("/hi");
  };
  useEffect(() => {
    setIsDropdownOpen(selectedFlag === "russian" ? false : true);
  }, [selectedFlag]);

  const handleLangChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFlagSelect = (flag) => {
    setSelectedFlag(flag);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <header className="langChange_header">
        <h1 className="langChange_h">
          Rock Paper <br />
          Scissors
        </h1>
        <div
          className={`langChange_box ${isDropdownOpen ? "active" : ""}`}
          onClick={handleLangChange}
        >
          <img
            className={`langChange_arrow ${isDropdownOpen ? "open" : ""}`}
            src={arrow}
            alt="Arrow"
          ></img>
          <div className={`langChange_flag ${isDropdownOpen ? "open" : ""}`}>
            {selectedFlag === "russian" ? (
              <img
                className={`langChange_flag_russian ${
                  selectedFlag === "russian" ? "selected" : ""
                } `}
                src={russian}
                alt="Russian Flag"
                onClick={() => handleFlagSelect("russian")}
              />
            ) : null}
            {selectedFlag === "british" ? (
              <img
                className={`langChange_flag_british ${
                  selectedFlag === "british" ? "selected" : ""
                }`}
                src={british}
                alt="British Flag"
                onClick={() => handleFlagSelect("british")}
              />
            ) : null}
          </div>
          <div className="langChange_dropdown">
            {selectedFlag === "russian" ? (
              <div
                className="langChange_flag_item"
                onClick={() => handleFlagSelect("british")}
              >
                <img
                  className="langChange_flag_british"
                  src={british}
                  alt="British Flag"
                />
              </div>
            ) : null}
            {selectedFlag === "british" ? (
              <div
                className="langChange_flag_item"
                onClick={() => handleFlagSelect("russian")}
              >
                <img
                  className="langChange_flag_russian"
                  src={russian}
                  alt="Russian Flag"
                />
              </div>
            ) : null}
          </div>
        </div>
      </header>

      <p className="codeForm_label">Enter invite code</p>
      <form className="codeForm">
        <input className="codeForm_input" placeholder="Code"></input>
        <button
          className="codeForm_button_next"
          type="submit"
          onClick={handleGoMain}
        >
          <p className="codeForm_button_p1">Continue</p>
          <img className="codeForm_button_icon" src={next}></img>
        </button>
        <button
          className="codeForm_button_vip"
          type="button"
          onClick={handleGoVip}
        >
          <p className="codeForm_button_p2">Get a VIP pass</p>
          <img className="codeForm_button_icon" src={vip}></img>
        </button>
      </form>

      <img className="logo" src={logo2} />
    </>
  );
};

export default PassPage;
