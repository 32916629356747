import React, { useState, useEffect } from "react";
import next from "../../../images/next1.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../utils/LanguageContext";
import useUserGet from "../../../hooks/useUserGet";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const BetModal = ({ onSearchLobby, tgId, noLobby, setNoLobby, lobbyFound }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [stakeMin, setStakeMin] = useState("100");
  const [stakeMax, setStakeMax] = useState("");
  const [maxWinsMin, setMaxWinsMin] = useState("1");
  const [maxWinsMax, setMaxWinsMax] = useState("");
  const [stakeMinError, setStakeMinError] = useState(false);
  const [stakeMaxError, setStakeMaxError] = useState(false);
  const [maxWinsMinError, setMaxWinsMinError] = useState(false);
  const [maxWinsMaxError, setMaxWinsMaxError] = useState(false);
  const [balanceError, setBalanceError] = useState(false);
  const [showNoLobby, setShowNoLobby] = useState(false);
  const { user: fetchedUser } = useUserGet(tgId);
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();

  /*useEffect(() => {
    if (noLobby === true) {
      setShowNoLobby(true);
    }
  }, [noLobby]);*/

  const handleInputChange = (e, setValue, setError) => {
    const value = e.target.value;
    setValue(value);
    setError(false);

    if (
      (!stakeMin ||
        (!isNaN(stakeMin) && parseInt(stakeMin) <= fetchedUser.balance)) &&
      (!stakeMax ||
        (!isNaN(stakeMax) && parseInt(stakeMax) <= fetchedUser.balance)) &&
      (!maxWinsMin || (!isNaN(maxWinsMin) && parseInt(maxWinsMin) <= 5)) &&
      (!maxWinsMax || (!isNaN(maxWinsMax) && parseInt(maxWinsMax) <= 5))
    ) {
      setShowNoLobby(false);
    }
  };

  const validateBet = (value) => {
    return (
      /^\d+$/.test(value) &&
      parseInt(value, 10) >= 100 &&
      parseInt(value, 10) % 10 === 0
    );
  };

  const handleFindOpponent = () => {
    setStakeMinError(false);
    setStakeMaxError(false);
    setMaxWinsMinError(false);
    setMaxWinsMaxError(false);
    setBalanceError(false);

    let hasError = false;
    if (noLobby === true) {
      setShowNoLobby(true);
    }
    if (
      !stakeMin ||
      !validateBet(stakeMin) ||
      parseInt(stakeMin) > fetchedUser.balance
    ) {
      setStakeMinError(true);
      hasError = true;
    }

    if (
      !stakeMax ||
      !validateBet(stakeMax) ||
      parseInt(stakeMax) > fetchedUser.balance
    ) {
      setStakeMaxError(true);
      hasError = true;
    }

    if (!maxWinsMin || isNaN(maxWinsMin) || parseInt(maxWinsMin) > 5) {
      setMaxWinsMinError(true);
      hasError = true;
    }

    if (!maxWinsMax || isNaN(maxWinsMax) || parseInt(maxWinsMax) > 5) {
      setMaxWinsMaxError(true);
      hasError = true;
    }

    if (!hasError) {
      const initData = window.Telegram.WebApp.initData;
      setShowNoLobby(false);
      impactOccurred("soft");
      onSearchLobby({
        action: "search_lobby",
        stake_min: parseInt(stakeMin),
        stake_max: parseInt(stakeMax),
        max_wins_min: parseInt(maxWinsMin),
        max_wins_max: parseInt(maxWinsMax),
        user_id: tgId,
        "data-check-string": initData,
      });
      if (noLobby === true) {
        setShowNoLobby(true);
      }
    }

  };

  return (
    <div className="modal__block_bet">
      <div className="bet__block">
        <h3 className="bet__block_h">
          {t("Bet")}:{" "}
          {(stakeMinError || stakeMaxError || balanceError) && (
            <p className="input-info" style={{ color: "red" }}>
              {(stakeMinError || stakeMaxError) &&
                (parseInt(stakeMin, 10) < 100 || parseInt(stakeMax, 10) < 100
                  ? t("min: 100")
                  : t("Bet must be a multiple of 10"))}
              {balanceError && t("Bet exceeds your balance")}
            </p>
          )}
        </h3>
        <div className="bet__block_form">
          <div className="form__box">
            <label className="bet__form_lable">{t("From")}</label>
            <div className="input__box">
              <input
                className={`bet__form_input ${
                  stakeMinError ? "input-error" : ""
                }`}
                value={stakeMin}
                onChange={(e) =>
                  handleInputChange(e, setStakeMin, setStakeMinError)
                }
              />
            </div>
          </div>
          <div className="form__box">
            <label className="bet__form_lable">{t("To")}</label>
            <div className="input__box">
              <input
                className={`bet__form_input ${
                  stakeMaxError || balanceError ? "input-error" : ""
                }`}
                value={stakeMax}
                onChange={(e) =>
                  handleInputChange(e, setStakeMax, setStakeMaxError)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bet__block">
        <h3 className="bet__block_h">
          {t("Number of wins")}:{" "}
          {(!maxWinsMinError && parseInt(maxWinsMin) > 5) ||
          (!maxWinsMaxError && parseInt(maxWinsMax) > 5) ? (
            <p className="input-info">{t("max")}: 5</p>
          ) : null}
        </h3>
        <div className="bet__block_form">
          <div className="form__box">
            <label className="bet__form_lable">{t("From")}</label>
            <input
              className={`bet__form_input ${
                maxWinsMinError ? "input-error" : ""
              }`}
              value={maxWinsMin}
              onChange={(e) =>
                handleInputChange(e, setMaxWinsMin, setMaxWinsMinError)
              }
            />
          </div>
          <div className="form__box">
            <label className="bet__form_lable">{t("To")}</label>
            <input
              className={`bet__form_input ${
                maxWinsMaxError ? "input-error" : ""
              }`}
              value={maxWinsMax}
              onChange={(e) =>
                handleInputChange(e, setMaxWinsMax, setMaxWinsMaxError)
              }
            />
          </div>
        </div>
      </div>

      <button
        className="vip_form_button"
        onClick={handleFindOpponent}
        disabled={stakeMinError || stakeMaxError}
      >
        <p
          className="vip_form_button_p"
          style={{ color: showNoLobby ? "red" : "black" }}
        >
          {showNoLobby
            ? t("Sorry, there are no such lobbies.")
            : t("Find lobby")}
        </p>
        <img className="vip_form_button_icon" src={next} alt="next" />
      </button>
    </div>
  );
};

export default BetModal;
