import { useState, useEffect } from 'react';

const useFetchCards = (tgId, claimed, refreshUser) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCards = async () => {
      setLoading(true);
      try {
        const initData = window.Telegram.WebApp.initData;
        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];
        const encodedDataCheckString = encodeURIComponent(data_check_string);

        const response = await fetch(`https://tg-back.itc-hub.ru/api/v1/cards?tg_id=${tgId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'data-check-string': initData,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCards(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, [tgId, claimed,refreshUser]);

  return { cards, loading, error };
};

export default useFetchCards;