import { useEffect } from "react";

function useTelegram(setUser) {
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();


      const user = tg.initDataUnsafe?.user;
      if (user) {
        const { id, username, first_name: firstName, last_name: lastName, userProfilePhotos } = user;
        setUser({ id, username, firstName, lastName, userProfilePhotos });
      } else {
        console.log("User data is not available.");
      }
    } else {
      console.log("Telegram WebApp is not available.");
    }
  }, [setUser]);
}

export default useTelegram;
