import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import PassPage from "./components/passPage/passPage";
import VipPass from "./components/vipPass/vipPass";
import MainPage from "./components/mainPage/mainPage";
import HiModal from "./components/modals/hiModal/hiModal";
import SexModal from "./components/modals/sexModal/sexModal";
import BetModal from "./components/modals/betModal/betModal";
import LoadingPage from "./components/loadingPage/loadingPage";
import LobbyModal from "./components/modals/lobbyModal/lobbyModal";
import LobbyPage from "./components/lobbyPage/lobbyPage";
import ShopPage from "./components/shopPage/shopPage";
import WarningModal from "./components/modals/warningModal/warningModal";
import GameField from "./components/game/gameField/gameField";
import useTelegram from "./hooks/useTelegram";
import QrCodeDisplay from "./components/qrCode";
import useUpdateUserData from "./hooks/useUpdateUserData";
import useUserGet from "./hooks/useUserGet";
import MainLoading from "./components/mainLoading/mainLoading";
import "./index.css";
import { LanguageProvider } from "./utils/LanguageContext";
import useTelegramDataVerification from "./hooks/useTelegramDataVerification";

function importAll(r) {
  return r.keys().map(r);
}

const imageSources = importAll(
  require.context("./images", false, /\.(png|jpe?g|svg)$/)
);
const fontSources = importAll(
  require.context("./vendor/fonts", false, /\.(woff|woff2|ttf|otf)$/)
);

const preloadImages = (srcArray) => {
  return Promise.all(
    srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    })
  );
};

const preloadFonts = (srcArray) => {
  return Promise.all(
    srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const font = new FontFace("FontName", `url(${src})`);
        font
          .load()
          .then(() => {
            document.fonts.add(font);
            resolve();
          })
          .catch(reject);
      });
    })
  );
};

function App() {
  const [user, setUser] = useState(null);
  useTelegram(setUser);
  const [tgId, setTgId] = useState(null);
  const [username, setUsername] = useState(null);
  const location = useLocation();
  const [gender, setGender] = useState("man");
  const [refreshUser, setRefreshUser] = useState(false);
  const [genderChanged, setGenderChanged] = useState(null);
  const [gameIsOver, setGameIsOver] = useState(false);
  const { isValid, error } = useTelegramDataVerification();

  const {
    user: fetchedUser,
    loading: userLoading,
    error: userError,
  } = useUserGet(tgId, gameIsOver);
  const [avatarChanged, setAvatarChanged] = useState();

  const [isMobile, setIsMobile] = useState(true);
  const [initialLanguage, setInitialLanguage] = useState(null);
  const [isBan, setIsBan] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Добавляем состояние загрузки
  const [headerColor, setHeaderColor] = useState("#101012"); // Начальный цвет заголовка
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF"); // Начальный цвет фона
  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([preloadImages(imageSources), preloadFonts(fontSources)])
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  const handleGameOver = () => {
    setGameIsOver(true);
  };

  useEffect(() => {
    if (fetchedUser.is_ban) {
      setIsBan(true);
    } else {
      setIsBan(false);
    }
  }, [fetchedUser]);

  const handleAvatarChanged = (newAvatar) => {
    setTimeout(() => {
      setAvatarChanged(newAvatar);
    }, 600); 
  };

  useEffect(() => {
    if (fetchedUser && fetchedUser.language) {
      const languageCode = fetchedUser.language === "ru" ? "ru" : "en";
      setInitialLanguage(languageCode);
    }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      setIsMobile(true);
    } else {
      //setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    if (isValid) {
      console.log("lalal");
    }
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();

      const tg_id = tg.initDataUnsafe.user.id;
      const username = tg.initDataUnsafe.user.username;

      setTgId(tg_id);
      localStorage.setItem("tg_id", tg_id);
      setUsername(username);

      tg.expand();

      /*const theme = tg.themeParams;
      applyTheme(theme);

      tg.onEvent("theme_changed", applyTheme);*/

      tg.onEvent("viewport_changed", (viewport) => {
        if (viewport.is_expanded) {
          console.log("Viewport is expanded to maximum height.");
        } else {
          console.log("Viewport height:", viewport.height);
        }
      });
    }
  }, []);

  /*const applyTheme = (theme) => {
    document.body.style.backgroundColor = "rgba(255, 255, 255, 1)";
  };*/

  useEffect(() => {
    
    switch (location.pathname) {
      case "/game":
        window.Telegram.WebApp.setHeaderColor("#2d2d2d");

        break;

      default:
        window.Telegram.WebApp.setHeaderColor("#fffbf9");
    }
  }, [location]);

  /*const applyTheme = () => {
    const tg = window.Telegram.WebApp;
    tg.setHeaderColor("#fffbf9");
  };

  useEffect(() => {
    applyTheme();
  }, []);*/

  useEffect(() => {
    const checkUser = setTimeout(() => {
      if (
        location.pathname === "/" &&
        userError &&
        !isLoading &&
        userError.message === "User not found"
      ) {
        navigate("/hi");
      } else if (location.pathname === "/" && fetchedUser && !isLoading) {
        navigate("/main");
      }
    }, 1500);

    return () => clearTimeout(checkUser);
  }, [fetchedUser, userError, navigate, isLoading]);


  
  /*useEffect(() => {
    const checkUser = setTimeout(() => {
      if (
        location.pathname === "/" &&
        !isLoading 
      ) {
        navigate("/pass");
      } 
    }, 1500);

    return () => clearTimeout(checkUser);
  }, [ navigate, isLoading]);*/

  useEffect(() => {
    const pageElement = document.querySelector(".page");
    if (location.pathname === "/game") {
      pageElement.style.margin = "0px";
      pageElement.style.backgroundColor = "rgba(91, 86, 251, 1)";
    }
  }, [location]);

  return (
    <LanguageProvider initialLanguage={initialLanguage}>
      {isMobile ? (
        <Routes>
          <Route path="/" element={<MainLoading />} />
          <Route path="/vip" element={<VipPass />} />
          <Route path="/pass" element={<PassPage/>}/>
          <Route
            path="/main"
            element={
              <MainPage
                fetchedUser={fetchedUser}
                user={user}
                tgId={tgId}
                gender={gender}
                setIsBan={setIsBan}
                isBan={isBan}
                setGameIsOver={setGameIsOver}
                handleGameOver={handleGameOver}
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
              />
            }
          />
          <Route
            path="/hi"
            element={<HiModal tgId={tgId} username={username} />}
          />
          <Route path="/gender" element={<SexModal tgId={tgId} />} />
          <Route
            path="/bet"
            element={<BetModal fetchedUser={fetchedUser} tgId={tgId} />}
          />
          <Route
            path="/loading"
            element={
              <LoadingPage fetchedUser={fetchedUser} user={user} tgId={tgId} />
            }
          />
          <Route
            path="/lobbyModal"
            element={<LobbyModal fetchedUser={fetchedUser} tgId={tgId} />}
          />
          <Route
            path="/lobby"
            element={
              <LobbyPage
                fetchedUser={fetchedUser}
                user={user}
                tgId={tgId}
                gender={gender}
                handleAvatarChanged={handleAvatarChanged}
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
                setGenderChanged={setGenderChanged}
                genderChanged={genderChanged}
                isBan={isBan}
              />
            }
          />
          <Route
            path="/shop"
            element={
              <ShopPage
                fetchedUser={fetchedUser}
                user={user}
                tgId={tgId}
                gender={gender}
                handleAvatarChanged={handleAvatarChanged}
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
                setGenderChanged={setGenderChanged}
                genderChanged={genderChanged}
              />
            }
          />
          <Route
            path="/game"
            element={
              <GameField
                fetchedUser={fetchedUser}
                user={user}
                tgId={tgId}
                gender={gender}
              />
            }
          />
          <Route path="/warning" element={<WarningModal />} />
        </Routes>
      ) : (
        <QrCodeDisplay />
      )}
    </LanguageProvider>
  );
}

export default App;
