import { useState, useEffect } from 'react';

const useGetRefs = (tg_id) => {
  const [refCodes, setRefCodes] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let userId = tg_id ?? localStorage.getItem('tg_id'); // Simplified conditional assignment

  useEffect(() => {
    const fetchRefs = async () => {
      try {
        const initData = window.Telegram.WebApp.initData;

        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

        const encodedDataCheckString = encodeURIComponent(data_check_string);

        const headers = {
          'Content-Type': 'application/json',
          'data-check-string': initData,
        };

        const response = await fetch(`https://tg-back.itc-hub.ru/api/v1/refcodes?tg_id=${userId}`, {
          headers: headers,
        });

        if (response.status === 200) {
          const data = await response.json();
          setRefCodes(data); 
        } else if (response.status === 404) {
          setError(new Error('No referral codes'));
        } else {
          throw new Error('Network response was not ok ' + response.statusText);
        }
      } catch (error) {
        setError(error);
        console.error('Error fetching user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRefs();
  }, [tg_id]);

  return { refCodes, loading, error, userId };
};

export default useGetRefs;
