import React from "react";
import nextWhite from "../../../images/nextWhite.svg";
import { useTranslation } from "react-i18next";

const TimeOffModal = ({
  noMoveUser,
  noMoveOpponent,
  setNoMoveUser,
  setNoMoveOpponent,
  onNextRound,
}) => {
  const { t } = useTranslation();

  return (
    <div className="modal__block" style={{ width: "310px", alignItems: "center", gap: "15px", zIndex:"50" }}>
      <h3 className="exitModal__h">{t('Time is over!')}</h3>
      <p className="exitModal__p">
        {noMoveUser
          ? t('You have not made a move within the allotted time, you can go to the next round.')
          : noMoveOpponent
          ? t('Your opponent didn\'t make a move within the allotted time, you can take the victory for yourself.')
          : ""}
      </p>

      <button
        className="exitModal__button"
        style={{
          backgroundColor: noMoveUser ? "red" : noMoveOpponent ? "green" : "",
        }}
        onClick={onNextRound} 
      >
        <p className="exitModal__button_p">{t('Next round')}</p>
        <img className="exitModal__button_icon" src={nextWhite} alt="next round" />
      </button>
    </div>
  );
};

export default TimeOffModal;
