import AppleWatchUltra from "../images/AppleUltra.png";
import AppleWatch from "../images/apple.jpeg";
import RolexWhiteGold from "../images/RolexWhite.png";
import RolexYellow from "../images/RolexGold.png";

import cartierWhite from "../images/cartierWhite.png";
import cartierYellow from "../images/cartierGold.png";
import oldSchool from "../images/oldRing.png";
import tiffany from "../images/tiffanyRing.png";

import oldSchoolBr from "../images/oldBr.png";
import tiffanyBr from "../images/tiffanyBr.png";
import VanCleef from "../images/vanBr.png";
import cartierBr from "../images/cartierBr.png";

export const items = [
  {
    id: 1,
    name: "Old School",
    description:
      "Minimalism and simplicity—everything you need to start farming points.",
    price: "0.3 ton",
    image: oldSchool,
    type: "ring",
  },
  {
    id: 2,
    name: "Tiffany",
    description: "Diamonds are not just for beauty; they also help you earn.",
    price: "0.3 ton",
    image: tiffany,
    type: "ring",
  },
  {
    id: 3,
    name: "Cartier Yellow Gold",
    description: "This ring brings serious income along with high status.",
    price: "0.3 ton",
    image: cartierYellow,
    type: "ring",
  },
  {
    id: 4,
    name: "Cartier White Gold",
    description:
      "A luxury attribute that will attract attention with its sophistication and its income potential.",
    price: "0.3 ton",
    image: cartierWhite,
    type: "ring",
  },
  {
    id: 5,
    name: "Old School Bracelet",
    description:
      "A bracelet for Old School lovers that helps speed up your progress.",
    price: "0.3 ton",
    image: oldSchoolBr,
    type: "bracelet",
  },
  {
    id: 6,
    name: "Van Cleef",
    description: "A gold bracelet symbolizing the success of its owner.",
    price: "0.3 ton",
    image: VanCleef,
    type: "bracelet",
  },

  {
    id: 7,
    name: "Cartier Bracelet",
    description:
      "A bracelet shaped like a nail that has not only a unique look but also unique characteristics.",
    price: "0.3 ton",
    image: cartierBr,
    type: "bracelet",
  },
  {
    id: 8,
    name: "Bvlgari Bracelet",
    description:
      "People will not only admire this jewelry but also envy how much you earn.",
    price: "0.3 ton",
    image: tiffanyBr,
    type: "bracelet",
  },

  {
    id: 9,
    name: "Apple Watch",
    description:
      "Smartwatch that makes time fly by, helping you farm efficiently.",
    price: "0.3 ton",
    image: AppleWatch,
    type: "watch",
  },

  {
    id: 10,
    name: "Apple Watch Ultra",
    description: "While you're busy, these watches will farm for you.",
    price: "0.3 ton",
    image: AppleWatchUltra,
    type: "watch",
  },

  {
    id: 11,
    name: "Rolex Yellow Gold",
    description:
      "A watch you look at not to tell the time, but to see how many points you've farmed.",
    price: "0.3 ton",
    image: RolexYellow,
    type: "watch",
  },
  {
    id: 12,
    name: "Rolex White Gold",
    description:
      "White gold watch from the Swiss brand Rolex, accelerating your earnings.",
    price: "0.3 ton",
    image: RolexWhiteGold,
    type: "watch",
  },
];

/*const tasks = [
  {
    id: 1,
    name: "Start",
    subtasks: [
      {
        name: "Подписаться на Telegram",
        reward: 1000,
        title: "Хорошее начало",
        image: tg,
        imageModal: message,
      },
      {
        name: "Достичь баланса в 15000 поинтов",
        reward: 1000,
        title: "Хорошее начало",
        image: meshok,
        imageModal: coins,
      },
      {
        name: "Сыграть 15 игр",
        reward: 1000,
        title: "Хорошее начало",
        image: mech,
        imageModal: goal,
      },
    ],
    completed: 0,
    total: 3,
  },

  {
    id: 2,
    name: "C-Тир",
    subtasks: [
      {
        name: "Не проиграть ни одного раунда за 3 игры",
        reward: 1000,
        title: "Мастер",
      },
      {
        name: "Выиграть 10 раундов с помощью бумаги",
        reward: 1000,
        title: "Эксперт по бумаге",
      },
      {
        name: "Выиграть 10 раундов с помощью ножниц",
        reward: 1000,
        title: "Эксперт по ножницам",
      },
      {
        name: "Выиграть 10 раундов с помощью камня",
        reward: 1000,
        title: "Эксперт по камню",
      },
      {
        name: "Выиграть 3 раунда подряд с помощью бумаги",
        reward: 2500,
        title: "Мастер бумажной игры",
      },
      {
        name: "Выиграть 3 раунда подряд с помощью камня",
        reward: 2500,
        title: "Мастер каменного боя",
      },
      {
        name: "Выиграть 3 раунда подряд с помощью ножниц",
        reward: 2500,
        title: "Мастер ножниц",
      },
      {
        name: "Выполнить все стартовые задания",
        reward: 1000,
        title: "Хорошее начало",
      },
      { name: "Сыграть 50 игр", reward: 3500, title: "Неутомимый" },
      {
        name: "Выиграть игру состоящую из 1 раунда 25 раз",
        reward: 1000,
        title: "Мастер одного раунда",
      },
      {
        name: "Выиграть игру состоящую из 3 раундов 25 раз",
        reward: 1500,
        title: "Мастер трёх раундов",
      },
      {
        name: "Выиграть игру состоящую из 5 раундов 25 раз",
        reward: 2000,
        title: "Мастер пяти раундов",
      },
      {
        name: "Выиграть 3 игры подряд при ставке от 1000 поинтов",
        reward: 1500,
        title: "Путь к успеху",
      },
      {
        name: "Выиграть 4 игр подряд при ставке от 1000 поинтов",
        reward: 2500,
        title: "Мастер маленьких ставок",
      },
      {
        name: "Выиграть 5 игр подряд при ставке от 1000 поинтов",
        reward: 3500,
        title: "Превосходная серия",
      },
      {
        name: "Выиграть больше 5000 поинтов за игру",
        reward: 1000,
        title: "Счастливчик",
      },
      {
        name: "Пригласить 1 игрока (он должен набрать как минимум 15к поинтов)",
        reward: 1500,
        title: "Всё только начинается",
      },
      {
        name: "Заработать 10 000 поинтов",
        reward: 2000,
        title: "Стартовый капитал",
      },
      { name: "Победить 3 игры подряд", reward: 1000, title: "Хет-трик" },
      { name: "Выиграть 25 игр", reward: 5000, title: "Победитель" },
      {
        name: "Выполнить все ежедневные задания 3 дня подряд",
        reward: 3000,
        title: "Работяга",
      },
    ],
    completed: 0,
    total: 21,
  },
  {
    id: 3,
    name: "B-Тир",
    subtasks: [
      {
        name: "Не проиграть ни одного раунда за 4 игры",
        reward: 2000,
        title: "Стойкий",
      },
      {
        name: "Выиграть 25 раундов с помощью бумаги",
        reward: 2500,
        title: "Мастер бумаги",
      },
      {
        name: "Выиграть 25 раундов с помощью ножниц",
        reward: 2500,
        title: "Властелин ножниц",
      },
      {
        name: "Выиграть 25 раундов с помощью камня",
        reward: 2500,
        title: "Король камней",
      },
      {
        name: "Выиграть 4 раунда подряд с помощью бумаги",
        reward: 3500,
        title: "Бумажный король",
      },
      {
        name: "Выиграть 4 раунда подряд с помощью камня",
        reward: 3500,
        title: "Крепкий орешек",
      },
      {
        name: "Выиграть 4 раунда подряд с помощью ножниц",
        reward: 3500,
        title: "Король ножниц",
      },
      { name: "Сыграть 100 игр", reward: 10000, title: "Закалённый игрок" },
      {
        name: "Выиграть игру состоящую из 1 раунда 50 раз",
        reward: 5000,
        title: "Эксперт одного раунда",
      },
      {
        name: "Выиграть игру состоящую из 3 раундов 50 раз",
        reward: 7500,
        title: "Эксперт трёх раундов",
      },
      {
        name: "Выиграть игру состоящую из 5 раундов 50 раз",
        reward: 10000,
        title: "Эксперт пяти раундов",
      },
      {
        name: "Выиграть 3 игры подряд при ставке от 5000 поинтов",
        reward: 2500,
        title: "Блестящая серия",
      },
      {
        name: "Выиграть 4 игры подряд при ставке 5000 поинтов",
        reward: 3500,
        title: "Удачливый игрок",
      },
      {
        name: "Выиграть 5 игры подряд при ставке 5000 поинтов",
        reward: 5000,
        title: "Неуязвимый",
      },
      {
        name: "Выиграть поставив все поинты (all-in) 1 раз",
        reward: 2500,
        title: "Рисковый",
      },
      {
        name: "Выиграть больше 10000 поинтов за игру",
        reward: 2500,
        title: "Крупный выигрыш",
      },
      {
        name: "Пригласить 5 игроков (он должен набрать как минимум 15к поинтов)",
        reward: 6000,
        title: "Дружелюбный",
      },
      {
        name: "Заработать 25 000 поинтов",
        reward: 2500,
        title: "Первый миллион",
      },
      {
        name: "Победить 4 игры подряд",
        reward: 2500,
        title: "Безупречная серия",
      },
      { name: "Выиграть 50 игр", reward: 5000, title: "Победитель" },
      {
        name: "Выполнить все ежедневные задания 7 дней подряд",
        reward: 7500,
        title: "Без выходных",
      },
    ],
    completed: 0,
    total: 21,
  },
  {
    id: 4,
    name: "A-Тир",
    subtasks: [
      {
        name: "Не проиграть ни одного раунда за 5 игр",
        reward: 3500,
        title: "Непревзойдённый стратег",
      },
      {
        name: "Выиграть 50 раундов с помощью бумаги",
        reward: 5000,
        title: "Бумажный виртуоз",
      },
      {
        name: "Выиграть 50 раундов с помощью ножниц",
        reward: 5000,
        title: "Эдвард руки-ножницы",
      },
      {
        name: "Выиграть 50 раундов с помощью камня",
        reward: 5000,
        title: "Каменный мастер",
      },
      {
        name: "Выиграть 5 раундов подряд с помощью бумаги",
        reward: 5000,
        title: "Знаток бумаги",
      },
      {
        name: "Выиграть 5 раундов подряд с помощью камня",
        reward: 5000,
        title: "Знаток камня",
      },
      {
        name: "Выиграть 5 раундов подряд с помощью ножниц",
        reward: 5000,
        title: "Знаток ножниц",
      },
      { name: "Сыграть 150 игр", reward: 15000, title: "Опытный игрок" },
      {
        name: "Выиграть игру состоящую из 1 раунда 100 раз",
        reward: 7500,
        title: "Спринтер",
      },
      {
        name: "Выиграть игру состоящую из 3 раундов 100 раз",
        reward: 10000,
        title: "Герой трёх раундов",
      },
      {
        name: "Выиграть игру состоящую из 5 раундов 100 раз",
        reward: 15000,
        title: "Герой пяти раундов",
      },
      {
        name: "Выиграть 5 игры подряд при ставке от 10000 поинтов",
        reward: 3500,
        title: "Герой больших ставок",
      },
      {
        name: "Выиграть 6 игры подряд при ставке от 10000 поинтов",
        reward: 5000,
        title: "Победитель",
      },
      {
        name: "Выиграть 7 игры подряд при ставке от 10000 поинтов",
        reward: 7500,
        title: "Серийный победитель",
      },
      {
        name: "Выиграть больше 20000 поинтов за игру",
        reward: 3500,
        title: "Огромный выигрыш",
      },
      {
        name: "Пригласить 10 игроков (он должен набрать как минимум 15000 поинтов)",
        reward: 12500,
        title: "Зазывала",
      },
      { name: "Заработать 5000 поинтов", reward: 5000, title: "Богач" },
      {
        name: "Победить 5 игр подряд",
        reward: 5000,
        title: "Беспроигрышная серия",
      },
      { name: "Выиграть 100 игр", reward: 10000, title: "Чемпион" },
      {
        name: "Выполнить все ежедневные задания 12 дней подряд",
        reward: 12500,
        title: "Целеустремлённый",
      },
      {
        name: "Попасть в топ-250 по количеству выигранных поинтов",
        reward: 7500,
        title: "Лидер по очкам",
      },
      {
        name: "Попасть в топ-250 по количеству игр",
        reward: 7500,
        title: "Лидер по играм",
      },
      {
        name: "Попасть в топ-250 по количеству побед",
        reward: 7500,
        title: "Лидер по победам",
      },
    ],
    completed: 0,
    total: 24,
  },
  {
    id: 5,
    name: "S-Тир",
    subtasks: [
      { name: "Сыграть 250 игр", reward: 25000, title: "Ветеран игры" },
      {
        name: "Выиграть 3 игры подряд при ставке 25000 поинтов",
        reward: 35000,
        title: "Профессионал своего дела",
      },
      {
        name: "Выиграть 4 игры подряд при ставке 25000 поинтов",
        reward: 50000,
        title: "Мастер больших ставок",
      },
      {
        name: "Выиграть 5 игр подряд при ставке 25000 поинтов",
        reward: 75000,
        title: "Любимец фортуны",
      },
      {
        name: "Выиграть, поставив все поинты 5 раз",
        reward: 20000,
        title: "Отчаянный",
      },
      {
        name: "Выиграть больше 50000 поинтов за игру",
        reward: 10000,
        title: "Сорвал куш",
      },
      {
        name: "Пригласить 25 игроков (он должен набрать как минимум 15000 поинтов)",
        reward: 30000,
        title: "Наставник",
      },
      {
        name: "Заработать 100000 поинтов",
        reward: 10000,
        title: "Огромный успех",
      },
      { name: "Победить 6 игр подряд", reward: 7500, title: "Непобедимый" },
      { name: "Выиграть 150 игр", reward: 17500, title: "Профессионал" },
      {
        name: "Выполнить все ежедневные задания 15 дней подряд",
        reward: 17500,
        title: "Эффективный",
      },
      {
        name: "Попасть в топ-100 по количеству выигранных поинтов",
        reward: 15000,
        title: "В десятке лучших",
      },
      {
        name: "Попасть в топ-100 по количеству игр",
        reward: 15000,
        title: "Неудержимый",
      },
      {
        name: "Попасть в топ-100 по количеству побед",
        reward: 15000,
        title: "Чемпион по победам",
      },
    ],
    completed: 0,
    total: 14,
  },
  {
    id: 6,
    name: "Special",
    subtasks: [
      {
        name: "Приобрести в магазине 1 предмет",
        reward: 10000,
        title: "Успешный старт",
      },
      { name: "Купить VIP-пропуск", reward: 10000, title: "VIP игрок" },
      {
        name: "Приобрести в магазине 2 предмета",
        reward: 20000,
        title: "Усиливая позиции",
      },
      {
        name: "Купить VIP-пропуск 2 раза",
        reward: 20000,
        title: "Премиум пользователь",
      },
      {
        name: "Приобрести в магазине 3 предмета",
        reward: 30000,
        title: "Удачный шопинг",
      },
      {
        name: "Приобрести в магазине 5 предметов",
        reward: 60000,
        title: "Магнат",
      },
      {
        name: "Купить VIP-пропуск 3 раза",
        reward: 30000,
        title: "Постоянный VIP",
      },
      {
        name: "Забрать 5000 поинтов из хранилища",
        reward: 1000,
        title: "Пассивный доход",
      },
      {
        name: "Получить уникальную аватарку 1 раз",
        reward: 2000,
        title: "Первый шаг коллекционера",
      },
      {
        name: "Забрать 10000 поинтов из хранилища",
        reward: 20000,
        title: "Золотой запас",
      },
      {
        name: "Получить уникальную аватарку 3 раза",
        reward: 5000,
        title: "Стиль и уникальность",
      },
      {
        name: "Забрать 25000 поинтов из хранилища",
        reward: 5000,
        title: "Майнер",
      },
      {
        name: "Участие в открытом бета-тесте",
        reward: 15000,
        title: "Первооткрыватель",
      },
      {
        name: "Получить уникальную аватарку 5 раз",
        reward: 25000,
        title: "Коллекционер аватарок",
      },
      {
        name: "Забрать 50000 поинтов из хранилища",
        reward: 25000,
        title: "Ничего не делая",
      },
      {
        name: "Участие в закрытом бета-тесте",
        reward: 30000,
        title: "Один из первых",
      },
    ],
    completed: 0,
    total: 16,
  },
  {
    id: 7,
    name: "Seasonal",
    subtasks: [
      {
        name: "Занять первое место в турнире",
        reward: 5000,
        title: "Чемпион турнира",
      },
      {
        name: "Занять второе место в турнире",
        reward: 2500,
        title: "Серебряный призёр турнира",
      },
      {
        name: "Занять третье место в турнире",
        reward: 1500,
        title: "Бронзовый призёр турнира",
      },
      {
        name: "Занять первое место в турнире 2 раза",
        reward: 10000,
        title: "Двукратный чемпион турнира",
      },
      {
        name: "Занять второе место в турнире 2 раза",
        reward: 5000,
        title: "Двукратный серебряный призёр турнира",
      },
      {
        name: "Занять третье место в турнире 2 раза",
        reward: 3000,
        title: "Двукратный бронзовый призёр турнира",
      },
      {
        name: "Занять первое место в турнире 3 раза",
        reward: 15000,
        title: "Трёхкратный чемпион турнира",
      },
      {
        name: "Занять второе место в турнире 3 раза",
        reward: 10000,
        title: "Трёхкратный серебряный призёр турнира",
      },
      {
        name: "Занять третье место в турнире 3 раза",
        reward: 5000,
        title: "Трёхкратный бронзовый призёр турнира",
      },
    ],
    completed: 0,
    total: 9,
  },
];*/





