import React, { useState, useEffect } from "react";
import womanHand from "../../../images/womanHand.png";
//import womanHand from "../../../images/womanHand1.svg";

import scissorsWom from "../../../images/scissorsWom.png";
import rockWom from "../../../images/rockWom.png";
import scissorsMan from "../../../images/scissorsMan.png";
import rockMan from "../../../images/rockMan.png";
import manHand from "../../../images/manHand.png";
//import manHand from "../../../images/manHand.svg";

const OpponentHand = ({opponentData, opponentSelection, players, tgId }) => {
  const [gender, setGender] = useState(false);
  const genderFromLocal = localStorage.getItem("genderUser");

    useEffect(() => {
      if (opponentData) {
        setGender(opponentData.gender);
      } /*if (players){
        console.log(players)
        const player= players.find((user) => user.tg_id !== tgId);
        setGender(player.gender);
       }*/
    }, [opponentData, /*players*/]);
  
    useEffect(() => {
      console.log('gender:', gender);
    }, [gender]);

  let handImage;
  if (gender === true) {    if (opponentSelection === "rock") {
      handImage = rockMan;
    } else if (opponentSelection === "scissors") {
      handImage = scissorsMan;
    } else if (opponentSelection === "paper") {
      handImage = manHand;
    } else {
      handImage = manHand;
    }
  } else {
    if (opponentSelection === "rock") {
      handImage = rockWom;
    } else if (opponentSelection === "scissors") {
      handImage = scissorsWom;
    } else if (opponentSelection === "paper") {
      handImage = womanHand;
    } else {
      handImage = womanHand;
    }
  }

  return (
    <div className="opponentHand">
      <img
        className={`opponentHand__pic ${opponentSelection ? "animate" : ""}`}
        src={handImage}
        alt={opponentSelection}
      ></img>
    </div>
  );
};

export default OpponentHand;
