import { useState, useEffect } from 'react';

const useUserGet = (tg_id, refreshUser, genderChanged, gameOver, gameIsOver) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let userId;
  if (tg_id === null) {
    userId = localStorage.getItem('tg_id');
  } else {
    userId = tg_id;
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const initData = window.Telegram.WebApp.initData;

        const pairs = initData.split('&').map(pair => pair.split('='));
        pairs.sort((a, b) => a[0].localeCompare(b[0]));
        const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
        const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

        const encodedDataCheckString = encodeURIComponent(data_check_string);

        const headers = {
          'Content-Type': 'application/json',
          'data-check-string': initData,
        };

        const response = await fetch(`https://tg-back.itc-hub.ru/api/v1/user?tg_id=${userId}`, {
          headers: headers,
        });

        if (response.status === 200) {
          const data = await response.json();
          setUser({
            tg_id: data.tg_id,
            username: data.username,
            avatar: data.avatar,
            balance: data.balance,
            gender: data.gender,
            language: data.language,
            is_ban: data.is_ban,
          });
        } else if (response.status === 404) {
          setError(new Error('User not found'));
        } else {
          throw new Error('Network response was not ok ' + response.statusText);
        }
      } catch (error) {
        setError(error);
        console.error('Error fetching user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [tg_id, refreshUser, genderChanged, gameOver, gameIsOver]);

  return { user, loading, error, userId };
};

export default useUserGet;