import React, { useState, useEffect } from "react";
import UserPanel from "../userPanel/userPanel";
import OpponentPanel from "../opponentPanel/opponentPanel";
import OpponentHand from "../opponentHand/opponentHand";
import UserHand from "../userHand/userHand";
import SelectionPanel from "../selectionPanel/selectionPanel";
import ExitModal from "../exitModal/exitModal";
import TimeOffModal from "../timeOffModal/timeOffModal";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import coin3 from "../../../images/coin3.svg";
import useUserGet from "../../../hooks/useUserGet";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const GameField = ({ user, gender, tgId }) => {
  const [showTimer, setShowTimer] = useState(false);
  const [timeValue, setTimeValue] = useState(0);
  const [userSelection, setUserSelection] = useState("");
  const [opponentSelection, setOpponentSelection] = useState("");
  const [opponentWin, setOpponentWin] = useState("");
  const [userWin, setUserWin] = useState("");
  const [round, setRound] = useState(0);
  const [roundNow, setRoundNow] = useState(1);
  const [opponentRound, setOpponentRound] = useState(0);
  const [roundCount, setRoundCount] = useState();
  const [resultMessage, setResultMessage] = useState("");
  const [winMessage, setWinMessage] = useState("");
  const [winMoney, setWinMoney] = useState("");
  const [money, setMoney] = useState(null);
  const [isUserReady, setIsUserReady] = useState(false);
  const [isOpponentReady, setIsOpponentReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showExitModalOnReload, setShowExitModalOnReload] = useState(false); // New state for showing Exit modal o
  const [userNewSelection, setUserNewSelection] = useState();
  const [searchParams, setSearchParams] = useState({});
  const [userData, setUserData] = useState(null);
  const [opponentData, setOpponentData] = useState(null);
  const [gameParams, setGameParams] = useState({});
  const { userWhoMadeLobby } = location.state || {};
  const { userWhoConnect } = location.state || {};
  //const { noReload } = location.state || {};
  const [socket, setSocket] = useState(null);
  const [noMoveUser, setNoMoveUser] = useState(false);
  const [waitingMove, setWaitingMove] = useState(false);
  const [whomWaiting, setWhomWaiting] = useState(null);
  const [whoWaiting, setWhoWaiting] = useState(null);

  const [noMoveOpponent, setNoMoveOpponent] = useState(false);
  const [userWinner, setUserWinner] = useState("");
  const [opponentWinner, setOpponentWinner] = useState("");
  const [draw, setDraw] = useState(false);
  const [opponentReady, setOpponentReady] = useState(false);
  const [exit, setExit] = useState(false);
  const [players, setPlayers] = useState({});
  const [gameOver, setGameOver] = useState(false);
  const [moreTime, setMoreTime] = useState(false);

  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState(t("Let’s go!"));
  const { user: fetchedUser, error: userError, getUser } = useUserGet(tgId);

  //sessionStorage.setItem("noReload", "true");

  /*useEffect(() => {
    const navigationEntry = performance.getEntriesByType("navigation")[0];

    const wasReload = sessionStorage.getItem("wasReload");

    if (navigationEntry.type === "reload" && !wasReload) {
      setShowExitModalOnReload(true);
      console.log("RELOADING");
      sessionStorage.setItem("wasReload", "true");
    } else {
      sessionStorage.removeItem("wasReload");
    }
  }, []);*/

  useEffect(() => {
    // Получаем запись навигации
    const navigationEntry = performance.getEntriesByType("navigation")[0];

    // Получаем значение из sessionStorage
    const wasReload = sessionStorage.getItem("wasReload");
    // Проверяем тип навигации
    if (
      navigationEntry.type === "reload" ||
      performance.navigation.type === performance.navigation.TYPE_RELOAD
    ) {
      if (!wasReload) {
        // Если это перезагрузка и нет флага в sessionStorage, показываем модалку
        setShowExitModalOnReload(true);
        sessionStorage.setItem("wasReload", "true");
      } else {
        // Если это не перезагрузка, удаляем флаг из sessionStorage
        sessionStorage.removeItem("wasReload");
      }
    } else {
      // Если это не перезагрузка, удаляем флаг из sessionStorage
      sessionStorage.removeItem("wasReload");
    }
  }, []);

  useEffect(() => {
    if (userWhoConnect) {
      const user = userWhoConnect.users.find((user) => user.tg_id === tgId);
      if (user) {
        setUserData(user);
      }
    }
  }, [userWhoConnect]);

  useEffect(() => {
    if (userWhoConnect) {
      const user = userWhoConnect.users.find((user) => user.tg_id !== tgId);
      if (user) {
        setOpponentData(user);
      }
    }
  }, [userWhoConnect]);

  useEffect(() => {
    if (userWhoConnect) {
      setSearchParams(userWhoConnect);
    }
  }, [userWhoConnect]);

  useEffect(() => {
    if (userWhoMadeLobby) {
      setSearchParams(userWhoMadeLobby);
    }
  }, [userWhoMadeLobby]);

  useEffect(() => {
    if (userWhoMadeLobby) {
      const user = userWhoMadeLobby.users.find((user) => user.tg_id === tgId);
      if (user) {
        setUserData(user);
      }
    }
  }, [userWhoMadeLobby]);

  useEffect(() => {
    if (userWhoMadeLobby) {
      const user = userWhoMadeLobby.users.find((user) => user.tg_id !== tgId);
      if (user) {
        setOpponentData(user);
      }
    }
  }, [userWhoMadeLobby]);

  useEffect(() => {

    const ws = new WebSocket(`wss://tg-back.itc-hub.ru/ws/game/${searchParams?.id}/`);

    ws.onopen = () => {
      socket.send(JSON.stringify(searchParams));
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.message === "Player info") {
        setRoundCount(message.wins);
        setPlayers(message.lobby.users);
      }
      if (message.message === "Waiting for the other user to confirm") {
        setWaitingMove(true);
        setWhomWaiting(message.waiting_for_user);
        setWhoWaiting(message.waiting_user);
      }
      if (message.message === "Round result") {
        const move = message.moves.find((move) => move.user != tgId);
        setOpponentSelection(move.move);
        setWaitingMove(false);
        setIsOpponentReady(true);

        if (message.moves.find((move) => move.user === tgId)) {
          const userWin = message.moves.find((move) => move.user === tgId);
          const opponentWin = message.moves.find((move) => move.user !== tgId);

          setUserWin(userWin.win);
          if (userWin.win === "True") {
            setRound((prevRound) => prevRound + 1);
          }
          if (userWin.move === "no move") {
            setNoMoveUser(true);
          }
          /*if (userWin.win === "None") {
            setDraw(true);
            console.log(draw)
          }*/
        }
        if (message.moves.find((move) => move.user !== tgId)) {
          const opponentWin = message.moves.find((move) => move.user !== tgId);
          const userWin = message.moves.find((move) => move.user === tgId);
          setOpponentWin(opponentWin.win);
          if (opponentWin.win === "True") {
            setOpponentRound((prevRound) => prevRound + 1);
          }
          if (opponentWin.move === "no move") {
            setNoMoveOpponent(true);
          }
          /*if (opponentWin.win === "None") {
            setDraw(true);
            console.log(draw)
          }*/
        }
      }
      if (message.message === "Match result") {
        const winner = message.winner.tg_id;
        setWaitingMove(false);
        if (winner == tgId) {
          setUserWinner("True");
        }
        if (winner !== tgId) {
          setOpponentWinner("True");
        }
        const result = message.result.loser.user;
        if (result == tgId) {
          setMoney(message.result.loser.sum_lose);
        } else {
          setMoney(message.result.winner.sum_win);
        }
      }

      if (message.message === "Notify user") {
        const readyUser = message.user;
        if (readyUser !== tgId) {
          setOpponentReady(true);
        }
      }

      if (message.message === "User has left the game") {
        const exitUser = message.user;
        if (exitUser !== tgId) {
          setExit(true);
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(ws);
  }, [searchParams]);

  const handleExit = (params) => {

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
    }
  };

  const handleConfirm = (params) => {
    setUserSelection(params.move);
    setIsUserReady(true);
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
    }
  };

  const openExitModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowExitModalOnReload(false); // Close the modal if it's open due to reload
  };

  const handleExitGame = () => {
    navigate("/main");
    const pageElement = document.querySelector(".page");

    pageElement.style.margin = "16px";
    pageElement.style.backgroundColor = "#FFFBF9";
  };

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setTextValue(t("Round 1 / Start"));
    }, 2000);

    const timer2 = setTimeout(() => {
      setTextValue("");
      const gameP = document.querySelector(".game__p");
      gameP.style.opacity = "0";
      setShowTimer(true);
    }, 4000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    let interval;
    let timeout;

    if (showTimer) {

      setTimeValue(30); // Устанавливаем начальное значение времени

      interval = setInterval(() => {
        setTimeValue((prevTime) => prevTime - 1); // Уменьшаем время на 1 секунду
      }, 1000);

      timeout = setTimeout(() => {
        if (!isUserReady) {
          setNoMoveUser(true);
        }
        if (!isOpponentReady) {
          setNoMoveOpponent(true);
          //setIsModalOpen(true);
        }
      }, 30000); // Установка времени, после которого будет срабатывать таймаут

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [showTimer, tgId, opponentWin, userWin]);

  useEffect(() => {
    if (waitingMove) {
      const timeoutId = setTimeout(() => {
        if (whomWaiting !== tgId) {
          const initData = window.Telegram.WebApp.initData;

          handleConfirm({
            action: "make_move",
            move: "no move",
            user_id: whomWaiting,
            "data-check-string": initData,
          });
       
        } else if (whomWaiting === tgId) {
          const initData = window.Telegram.WebApp.initData;

          handleConfirm({
            action: "make_move",
            move: "no move",
            user_id: tgId,
            "data-check-string": initData,
          });
        }
      }, 30000);

      return () => clearTimeout(timeoutId);
    }
  }, [waitingMove]);

  const formatTime = (seconds) => {
    if (seconds < 0) {
      return "0:00";
    }
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (timeValue === 0 && waitingMove && !isOpponentReady) {
        const gameP = document.querySelector(".game__p");
        gameP.style.opacity = "1";
        setTextValue(t("We gave some more time for your opponent.."));
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [timeValue, waitingMove]);

  useEffect(() => {
    if (userWin !== "") {
      if (userWin === "True") {
        const gameP = document.querySelector(".game__p");
        gameP.style.opacity = "0";
        setResultMessage(`${t("YOU WIN")}${roundNow} ${t("ROUND")}`);
        setWaitingMove(false);
      } else {
        const gameP = document.querySelector(".game__p");
        gameP.style.opacity = "0";
        setResultMessage(`${t("YOU LOSE")}${roundNow} ${t("ROUND")}`);
        setWaitingMove(false);
      }
    }
  }, [userWin]);

  useEffect(() => {
    let timeoutId;
    if (userWinner === "True" || opponentWinner === "True") {
      const gameP = document.querySelector(".game__p");
      gameP.style.opacity = "0";
      timeoutId = setTimeout(() => {
        if (userWinner === "True") {
          impactOccurred("medium");
          setWinMessage(t("YOU WIN!"));
          setWinMoney(`${t("RewarD")} ${money}`);
          localStorage.setItem("balance", fetchedUser.balance);

          setTimeout(() => {
            setWinMessage("");
            setWinMoney("");
            setGameOver(true);
            navigate("/main", { state: { gameOver } });

            sessionStorage.setItem("wasReload", "true");
            const pageElement = document.querySelector(".page");
            pageElement.style.margin = "0 16px";
            pageElement.style.backgroundColor = "#fffbf9";
          }, 5000);
        }

        if (opponentWinner === "True") {
          setWinMessage(t("YOU LOSE :("));
          localStorage.setItem("balance", fetchedUser.balance);
          setWinMoney(`${t("BeT")} ${money}`);
          setTimeout(() => {
            setWinMessage("");
            setWinMoney("");
            setGameOver(true);
            navigate("/main", { state: { gameOver } });
            sessionStorage.setItem("wasReload", "true");
            const pageElement = document.querySelector(".page");
            pageElement.style.margin = "0 16px";
            pageElement.style.backgroundColor = "#fffbf9";
          }, 5000);
        }
        return () => {
          clearTimeout(timeoutId);
        };
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [userWinner, opponentWinner, money]);

  useEffect(() => {
    if (
      userWinner !== "True" &&
      opponentWinner !== "True" &&
      userWin !== "" &&
      userWin !== "None" &&
      opponentWin !== "None"
    ) {
      const timer = setTimeout(() => {
        setUserWin("");
        setRoundNow(roundNow + 1);
        setTimeValue(0);
        setUserSelection("");
        setOpponentSelection("");
        setIsUserReady(false);
        setNoMoveOpponent(false);
        setNoMoveUser(false);
        setIsOpponentReady(false);
        setOpponentReady(false);
        setTextValue(`${t("Round")}${roundNow + 1} ${t("/ Start")}`);
        const gameP = document.querySelector(".game__p");
        gameP.style.opacity = "1";
        setTimeout(() => {
          gameP.style.opacity = "0";
        }, 2000);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [userWin, round, userWinner, opponentWinner, opponentWin]);

  const [showResultMessage, setShowResultMessage] = useState(false);

  useEffect(() => {
    if (userWinner !== "True" && opponentWinner !== "True" && userWin !== "") {
      const timer = setTimeout(() => {
        setShowResultMessage(true);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setShowResultMessage(false);
    }
  }, [userWinner, opponentWinner, userWin]);

  useEffect(() => {
    if (opponentWin === "None" || userWin === "None") {
      setNoMoveOpponent(false);
      setNoMoveUser(false);
      const timer = setTimeout(() => {
        setDraw(true);
        setNoMoveOpponent(false);
        setNoMoveUser(false);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setDraw(false);
    }
  }, [opponentWin, userWin]);

  useEffect(() => {
    if (draw) {
      const timer = setTimeout(() => {
        setDraw(false);
        setUserWin("");
        setOpponentWin("");
        setTimeValue(0);
        setOpponentReady(false);
        setUserSelection("");
        setOpponentSelection("");
        setIsUserReady(false);
        setIsOpponentReady(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [draw]);

  const handleNextRound = () => {
    if (userWinner !== "True" && opponentWinner !== "True") {
      setUserWin("");
      setRoundNow(roundNow + 1);
      setTimeValue(0);
      setUserSelection("");
      setOpponentSelection("");
      setIsUserReady(false);
      setNoMoveOpponent(false);
      setNoMoveUser(false);
      setOpponentReady(false);
      setIsOpponentReady(false);
      setTextValue(t("Round")`${roundNow + 1}`(t("/ Start")));
      const gameP = document.querySelector(".game__p");
      gameP.style.opacity = "1";
      setTimeout(() => {
        gameP.style.opacity = "0";
      }, 2000);
    }
  };

  return (
    <div className="gameField">
      <OpponentPanel
        opponentData={opponentData}
        isReady={isOpponentReady}
        roundCount={roundCount}
        opponentRound={opponentRound}
        opponentReady={opponentReady}
      />
      <OpponentHand
        opponentSelection={opponentSelection}
        opponentData={opponentData}
        players={players}
        tgId={tgId}
      />
      <button className="gameField__exit" onClick={openExitModal}>
        {t("Exit")}
      </button>
      <p className="game__p animated-text">{textValue}</p>
      {showTimer && (
        <>
          <div className="timer__block">
            <div className="timer">{formatTime(timeValue)}</div>
            <p className="timer__p">
              {t("Round")} {roundNow}
            </p>
          </div>
          {userSelection ? (
            <div className="selection__result">
              <button className="selection__button selected">
                {userSelection === "rock"
                  ? t("Rock")
                  : userSelection === "scissors"
                  ? t("Scissors")
                  : userSelection === "paper"
                  ? t("Paper")
                  : t("No move")}
              </button>
            </div>
          ) : (
            <SelectionPanel
              tgId={tgId}
              gameParams={gameParams}
              onConfirm={handleConfirm}
              noMoveUser={noMoveUser}
              noMoveOpponent={noMoveOpponent}
              opponentData={opponentData}
            />
          )}
        </>
      )}
      <UserHand
        userData={userData}
        userWin={userWin}
        selection={userSelection}
        gender={gender}
        userWinner={userWinner}
        opponentWinner={opponentWinner}
        players={players}
        tgId={tgId}
      />
      <UserPanel
        roundCount={roundCount}
        userData={userData}
        isReady={isUserReady}
        user={user}
        round={round}
        fetchedUser={fetchedUser}
      />
      {isModalOpen && (
        <div className="modal__overlay" onClick={closeModal}>
          <div className="modal__content" onClick={(e) => e.stopPropagation()}>
            <ExitModal
              showExitModalOnReload={showExitModalOnReload}
              onExit={handleExit}
              tgId={tgId}
            />
          </div>
        </div>
      )}
      {showExitModalOnReload && (
        <div className="modal__overlay">
          <div className="modal__content" onClick={(e) => e.stopPropagation()}>
            <ExitModal
              showExitModalOnReload={showExitModalOnReload}
              onExit={handleExit}
              tgId={tgId}
            />
          </div>
        </div>
      )}
      {userWin !== "None" && showResultMessage && (
        <div
          className={`result-message ${userWin === "True" ? "win" : "lose"}`}
        >
          {resultMessage}
        </div>
      )}

      {winMessage && !exit && (
        <div
          className={`win-message ${userWinner === "True" ? "win" : "lose"}`}
        >
          <p className="winMessage">{winMessage}</p>
          <div className="money_block">
            <p className="winMoney">{winMoney}</p>
            <img className="lobby__bet_icon" src={coin3} />
          </div>
        </div>
      )}

      {winMessage && exit && (
        <div
          className={`win-message ${userWinner === "True" ? "win" : "lose"}`}
        >
          <p className="winMessage">{winMessage}</p>
          <p className="winMessage">
            {t("Your opponent has left the game :)")}
          </p>
          <div className="money_block">
            <p className="winMoney">{winMoney}</p>
            <img className="lobby__bet_icon" src={coin3} />
          </div>
        </div>
      )}
      {draw && (
        <div className={`result-message`}>
          <p className="winMessage">{t("Draw! The round will be replayed")}</p>
        </div>
      )}

      {!draw &&
      whomWaiting === tgId &&
      (opponentWin !== "" || userWin !== "") &&
      (noMoveUser || noMoveOpponent) &&
      roundCount > roundNow ? (
        <div className="modal__overlay" onClick={closeModal}>
          <div className="modal__content" onClick={(e) => e.stopPropagation()}>
            <TimeOffModal
              noMoveUser={noMoveUser}
              noMoveOpponent={noMoveOpponent}
              onExit={handleExitGame}
              setNoMoveUser={setNoMoveUser}
              setNoMoveOpponent={setNoMoveOpponent}
              onNextRound={handleNextRound}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GameField;
