import React, { useState, useEffect } from "react";
import coin3 from "../../images/coin3.svg";
import next1 from "../../images/next1.svg";
import UserHeader from "../userHeader/userHeader";
import LobbyModal from "../modals/lobbyModal/lobbyModal";
import BetModal from "../modals/betModal/betModal";
import GoMain from "../../images/GoMain.svg";
import people from "../../images/people.svg";
import shop from "../../images/bag.svg";
import arrowUpBlack from "../../images/BlackUp.png";
import arrowDownBlack from "../../images/BlackDown.png";
import arrowUpWhite from "../../images/WhiteUp.png";
import arrowDownWhite from "../../images/WhiteDown.png";
import { useNavigate } from "react-router-dom";
import useLobbyData from "../../hooks/useLobbyData";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../utils/LanguageContext";
import LoadingPage from "../loadingPage/loadingPage";
import useUserGet from "../../hooks/useUserGet";
import SkeletonLoader from "../loader";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

const LobbyPage = ({ user, gender, onGenderChange, tgId, handleAvatarChanged,
  refreshUser,
  setRefreshUser,
  setGenderChanged,
  genderChanged, isBan,  }) => {  
  const { t } = useTranslation();
  const { language } = useLanguage(); // Предполагается, что useLanguage предоставляет функциональность изменения языка
  const [loading, setLoading] = useState(false);
  const [showLobbyModal, setShowLobbyModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [sortBy, setSortBy] = useState("stake");
  const [sortDirection, setSortDirection] = useState("asc");
  const [lobbyDataMessage, setLobbyDataMessage] = useState({});
  const [lobbyData, setLobbyData] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState({});
  const [lobbysId, setLobbysId] = useState(null);
  const [lobbyWithId, setLobbyWithId] = useState({});
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [goGame, setGoGame] = useState(false);
  const [userExit, setUserExit] = useState(false);
  const [ownerExit, setOwnerExit] = useState(false);
  const [noConfirm, setNoConfirm] = useState(false);
  const { user: fetchedUser, error: userError, getUser } = useUserGet(tgId);  
  const [loader, setLoader] = useState(false);
  const [impactOccurred, notificationOccurred, selectionChanged] =
  useHapticFeedback();
 

  const handleGoShop = () => {
    impactOccurred("soft");
    navigate("/shop");
  };

  useEffect(() => {
    sessionStorage.setItem("wasReload", "true");
  }, []);


  const handleGoMain = () => {
    impactOccurred("soft");
    navigate("/main");
  };

  const handleGoLobby = () => {
    impactOccurred("soft");
    navigate("/lobby");
  };

  

  const toggleSortBy = (type) => {
    impactOccurred("soft");
    if (sortBy === type) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(type);
      setSortDirection("asc");
    }
  };

  const handleAddLobby = () => {
    const tg = window.Telegram.WebApp;
    tg.setHeaderColor("rgba(72, 72, 71)");
    impactOccurred("soft");
    setShowLobbyModal(true);
  };

  const handleGoRandomGame = () => {
    impactOccurred("soft");
    setShowBetModal(true);
  };

  const handleCloseModal = () => {
    setShowLobbyModal(false);
    const tg = window.Telegram.WebApp;
    tg.setHeaderColor("#fffbf9");
    setShowBetModal(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal__overlay")) {
      handleCloseModal();
    }
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const handleLobbyClick = (lobbyId, canJoin) => {
    impactOccurred("soft");
    if (!canJoin) return;
    const initData = window.Telegram.WebApp.initData;

    setLobbyWithId({
      action: "join_lobby",
      lobby_id: lobbyId,
      user_id: tgId,
      "data-check-string": initData,

    });
  };

  const handleConfirm = (params) => {

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
    }
  };

  const handleExit = (params) => {

    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(params));
    }
  };

  useEffect(() => {

    if (Object.keys(lobbyWithId).length > 0) {
      const socket = new WebSocket("wss://tg-back.itc-hub.ru/ws/lobby_search/");

      socket.onopen = () => {
        socket.send(JSON.stringify(lobbyWithId));
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (
          message.message === "No lobby found, waiting for one to appear..."
        ) {
          //setNoLobby(true);
        } else if (message.message === "Lobby found") {
          setLoading(true);
          setLobbyDataMessage(message);
          setLobbyData(message);
          //navigate("/loading", { state: { message } });
        }
        if (
          message.message === "No lobby found, waiting for one to appear..."
        ) {
          //setNoLobby(true);
          console.log("No lobby TEST");
        }
        if (message.message === "Waiting for the other user to confirm") {
          setWaiting(true);
        }
        if (message.message === "Waiting for the other user to confirm") {
          setNoConfirm(true);
        }
        if (message.message === "Game started") {
          setGoGame(true);
          setNoConfirm(false);
          //setNoReload(true);
        }
       
        if (message.message === "User exit lobby") {
          const owner = message.lobby_owner;
          const exitUser = message.user;
          if (exitUser !== tgId && owner === false) {
            setUserExit(true);
          }
          if (exitUser !== tgId && owner === true) {
            setOwnerExit(true);
          }
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = (event) => {
        console.log("WebSocket closed:", event);
      };
      setSocket(socket);
    }
  }, [lobbyWithId]);

  useEffect(() => {
    const socket = new WebSocket("wss://tg-back.itc-hub.ru/ws/lobbies/");
    const initData = window.Telegram.WebApp.initData;

    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          tg_id: tgId,
          sort_field: sortBy,
          sort_order: sortDirection === "asc" ? "asc" : "-asc",
          "data-check-string": initData,
        })
      );
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.message === "Some lobby found"){
      setLobbyData(message.lobby);
      setLoader(false); // Устанавливаем загрузку в false, когда данные получены
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket closed:", event);
    };

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [tgId, sortBy, sortDirection]);

  return (
    <>
      {!loading ? (
        <div className="main__block">
          <UserHeader
            user={user}
            tgId={tgId}
            gender={gender}
            onGenderChange={onGenderChange}
            handleAvatarChanged={handleAvatarChanged}
            refreshUser={refreshUser}
            setRefreshUser={setRefreshUser}
            setGenderChanged={setGenderChanged}
            genderChanged={genderChanged}
          />
          <div className="lobby__block">
            <h2 className="lobby__h">{t("Lobbies")}</h2>
            <div className="lobby__sort">
              <div
                className="lobby__sort_box"
                style={{
                  borderRight: "1px solid rgba(164, 164, 164, 1)",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
              >
                <button
                  className={`lobby__sort_box_button ${
                    sortBy === "stake" ? "active" : ""
                  }`}
                  style={{ padding: "8px 3px 8px 10px", width: "140.31px" }}
                  onClick={() => toggleSortBy("stake")}
                >
                  {t("BET")}
                  <div className="arrows">
                    <img
                      src={
                        sortBy === "stake" && sortDirection === "asc"
                          ? arrowUpBlack
                          : arrowUpWhite
                      }
                      alt="sort up"
                      className="sort-arrow"
                    />
                    <img
                      src={
                        sortBy === "stake" && sortDirection === "desc"
                          ? arrowDownBlack
                          : arrowDownWhite
                      }
                      alt="sort down"
                      className="sort-arrow"
                      style={{ marginLeft: "-10px" }}
                    />
                  </div>
                </button>
              </div>
              <div className="lobby__sort_box" style={{ paddingLeft: "13px" }}>
                <button
                  style={{ padding: "8px 3px 8px 13px" }}
                  className={`lobby__sort_box_button ${
                    sortBy === "max_win" ? "active" : ""
                  }`}
                  onClick={() => toggleSortBy("max_win")}
                >
                  {t("ROUNDS")}
                  <div className="arrows">
                    <img
                      src={
                        sortBy === "max_win" && sortDirection === "asc"
                          ? arrowUpBlack
                          : arrowUpWhite
                      }
                      alt="sort up"
                      className="sort-arrow"
                    />
                    <img
                      src={
                        sortBy === "max_win" && sortDirection === "desc"
                          ? arrowDownBlack
                          : arrowDownWhite
                      }
                      alt="sort down"
                      style={{ marginLeft: "-10px" }}
                      className="sort-arrow"
                    />
                  </div>
                </button>
              </div>
            </div>

            <div className="lobby__boxes">
              {isBan ? (
                <div
                className="lobby__box" 
                style={{  backgroundColor: "#a3a3a3"}}              >
                <p className="lobby__ban"             >
                  {t("You are blocked for 5 minutes")}
                  
                </p>
                </div>
              ) : (
                <>
                  {loader ? (
                    <SkeletonLoader /> // Показываем загрузчик, пока данные загружаются
                  ) : lobbyData.length === 0 ? (
                    <div className="lobby__no-lobbies">
                     {t("There are no lobbies, you can create the first one")}
                    </div>
                  ) : (
                    lobbyData.map((lobby) => (
                      <div
                        key={lobby.id}
                        className={`lobby__box ${
                          !lobby.can_join ? "disabled" : ""
                        }`}
                        onClick={() => handleLobbyClick(lobby.id, lobby.can_join)}
                      >
                        <p className="lobby__player-name">
                          {truncateString(lobby.users[0].username, 10)}
                        </p>
                        <div className="lobby__bet">
                          <p className="lobby__bet_p">{t("Bet")}</p>
                          <div className="lobby__bet_box">
                            <img
                              className="lobby__bet_icon"
                              src={coin3}
                              alt="bet icon"
                            />
                            <p className="lobby__bet_amount">{lobby.stake}</p>
                          </div>
                        </div>
                        <div className="round__block">
                          <p className="round__p">{t("Rounds")}</p>
                          <p className="round__amount">{lobby.max_wins}</p>
                        </div>
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          </div>
          <button
            className={`play__button ${isBan ? "disabled" : ""}`}
            onClick={isBan ? null : handleAddLobby}
            disabled={isBan}
          >
            <p className="play__button_p">{t("Create lobby")}</p>
            <img className="play__button_icon" src={next1} alt="next icon" />
          </button>
          <div className="navigation__block">
            <button className="navigation__block_button" onClick={handleGoMain}>
              <img
                className="navigation__block_icon"
                src={GoMain}
                alt="main icon"
              />
              <p className="navigation__block_p">{t("Main")}</p>
            </button>
            <button
              className="navigation__block_button"
              onClick={handleGoLobby}
            >
              <img
                className="navigation__block_icon"
                src={people}
                alt="lobby icon"
              />
              <p className="navigation__block_p">{t("Lobby")}</p>
            </button>
            <button className="navigation__block_button" onClick={handleGoShop}>
              <img
                className="navigation__block_icon"
                src={shop}
                alt="shop icon"
              />
              <p className="navigation__block_p">{t("Shop")}</p>
            </button>
          </div>
          {showLobbyModal && (
            <div className="modal__overlay" onClick={handleOverlayClick}>
              <LobbyModal
                onClose={handleCloseModal}
                lobbyData={lobbyData}
                tgId={tgId}
              />
            </div>
          )}
          {showBetModal && (
            <div className="modal__overlay" onClick={handleOverlayClick}>
              <BetModal
                onClose={handleCloseModal}
                onCreateLobby={handleGoRandomGame}
              />
            </div>
          )}
        </div>
      ) : (
        <LoadingPage
          lobbyDataMessage={lobbyDataMessage}
          tgId={tgId}
          onConfirm={handleConfirm}
          onExit={handleExit}
          waiting={waiting}
          goGame={goGame}
          setUserExit={setUserExit}
          userExit={userExit}
          ownerExit={ownerExit}
          setOwnerExit={setOwnerExit}
          noConfirm={noConfirm}
          setNoConfirm={setNoConfirm}
          
          //noReload={noReload}
        />
      )}
    </>
  );
};

export default LobbyPage;