import { useState } from 'react';

const useLanguageUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateLanguage = async (tgId, language) => {
    setLoading(true);
    setError(null);
    try {
      const initData = window.Telegram.WebApp.initData;
      const pairs = initData.split('&').map(pair => pair.split('='));
      pairs.sort((a, b) => a[0].localeCompare(b[0]));
      const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
      const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];
      const encodedDataCheckString = encodeURIComponent(data_check_string);

      const response = await fetch('https://tg-back.itc-hub.ru/api/v1/language', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'data-check-string': initData,
        },
        body: JSON.stringify({
          tg_id: tgId,
          language
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { updateLanguage, loading, error };
};

export default useLanguageUpdate;