import React, { useState, useEffect } from "react";
import settings from "../../../images/settings.png";
import rockWom from "../../../images/rockWom.png";
import scissorsMan from "../../../images/scissorsMan.png";
import coin3 from "../../../images/coin3.svg";
import { useNavigate } from "react-router-dom";
import SettingsModal from "../settingsModal/settingsModal";
import AvatarModal from "../avatarModal/avatarModal";
import backButton1 from "../../../images/backButton1.svg";
import useUserGet from "../../../hooks/useUserGet";
import useFetchAvatars from "../../../hooks/useFetchAvatars";
import usePostCards from "../../../hooks/usePostCards";
import useFetchCards from "../../../hooks/useFetchCards";
import { useLanguage } from "../../../utils/LanguageContext";
import { useTranslation } from "react-i18next";
import useCountdownTimer from "../../../hooks/useCountdownTimer";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import Referrals from "../refferals/refferals";
import TaskList from "../tasks/tasks";
const DailyModal = ({
  onClose,
  user,
  tgId,
  onGenderChange,
  onAvatarSelect,
  refreshUser,
  setRefreshUser,
  setGenderChanged,
  genderChanged,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [claimed, setClaimed] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [claimCheck, setClaimCheck] = useState(false);
  const [userAvatar, setUserAvatar] = useState();
  const [userBalance, setUserBalance] = useState();
  const [nextCardId, setNextCardId] = useState(1);
  const { postCards, response } = usePostCards();
  const [showReferrals, setShowReferrals] = useState(false);
  const [showDaily, setShowDaily] = useState(true);
  const [showTasks, setShowTasks] = useState(false);
  const [isBonusesModalOpen, setBonusesModalOpen] = useState(false); // Track BonusesModal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const {
    user: fetchedUser,
    loading: userLoading,
    error: userError,
  } = useUserGet(tgId, refreshUser, genderChanged);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userAvatar) {
      setLoading(false);
    }
  }, [userAvatar]);

  useEffect(() => {
    if (fetchedUser && fetchedUser.avatar) {
      const fullAvatarUrl = `https://tg-back.itc-hub.ru${fetchedUser.avatar.image}`;
      setUserAvatar(fullAvatarUrl);
      setUserBalance(fetchedUser.balance);
    }
  }, [fetchedUser]);

  const { avatars, error } = useFetchAvatars();

  const baseURL = "https://tg-back.itc-hub.ru";

  const { cards } = useFetchCards(tgId, claimed, refreshUser);

  useEffect(() => {
    if (cards && cards.card_info) {
      const completedCards = cards.card_info.filter((card) => card.completed);
      if (cards.can_done) {
        if (completedCards.length > 0) {
          const lastCompletedCardId =
            completedCards[completedCards.length - 1].id_custom;
          setNextCardId(lastCompletedCardId + 1);
        } else {
          setNextCardId(1);
        }
      } else {
        setClaimed(true);
        localStorage.setItem("claim", "true");
      }
    }
  }, [cards]);

  const handleClaimReward = async () => {
    if (nextCardId) {
      impactOccurred("soft");
      await postCards(tgId, nextCardId);
      setClaimed(true);
      localStorage.setItem("balance", fetchedUser.balance);
      localStorage.setItem("claim", "true");
      const cardElement = document.getElementById(nextCardId);
      if (cardElement) {
        cardElement.classList.add("claimed");
      }
      setRefreshUser((prev) => !prev);
    }
  };

  /*useEffect(() => {
    const claimCheck = localStorage.getItem("claim");
    console.log(claimCheck);
    if (claimCheck === "true") {
      setClaimCheck(true);
    }
  }, []);*/

  const handleOpenSettings = () => {
    impactOccurred("soft");
    setShowSettings(true);
  };

  const handleCloseSettings = (e) => {
    e.stopPropagation();
    setShowSettings(false);
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const handleOpenAvatarModal = (e) => {
    impactOccurred("soft");
    e.stopPropagation();
    setShowAvatarModal(true);
  };

  const handleCloseAvatarModal = (e) => {
    e.stopPropagation();
    setShowAvatarModal(false);
  };

  const avatarSelect = (newAvatar) => {
    setUserAvatar(newAvatar);
  };

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  };

  const formatBalance = (balance) => {
    if (balance < 10000000) {
      return balance;
    }
    const millionBalance = (balance / 1000000).toFixed(1);
    return `${millionBalance}M`;
  };

  const rewardAmounts = [
    250, 500, 750, 1250, 1500, 1750, 2000, 2500, 3000, 3500, 4500, 5500,
  ];

  const countdownTime = cards?.last_time || {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const { hours, minutes, seconds } = useCountdownTimer(
    countdownTime.hours,
    countdownTime.minutes,
    countdownTime.seconds
  );

  const handleOpenReferrals = () => {
    impactOccurred("soft");
    setShowReferrals(true);
    setShowDaily(false);
    setShowTasks(false);
  };

  const handleOpenDaily = () => {
    impactOccurred("soft");
    setShowDaily(true);
    setShowTasks(false);
    setShowReferrals(false);
  };

  const handleOpenTasks = () => {
    impactOccurred("soft");
    setShowTasks(true);
    setShowDaily(false);
    setShowReferrals(false);
  };

  const getButtonStyle = (isActive) => {
    return {
      backgroundColor: isActive ? "#5b56fb" : "transparent",
      color: isActive ? "white" : "black",
    };
  };
  return (
    <div
      className="modal__overlay"
      onClick={onClose}
      style={{
        background: isBonusesModalOpen || isModalOpen? "#2d2d2d" : "rgba(45, 45, 45, 0.8)",
      }}
    >
      <div className="modal__content" onClick={handleClick}>
        <div className="modal__block_daily">
          <div className="modal__box_daily">
            {showDaily && (
              <>
                <div className="daily__modal_buttons">
                  <button className="backButton_daily" onClick={onClose}>
                    <img
                      className="backButton_daily_icon"
                      src={backButton1}
                      alt="Back"
                    />
                  </button>
                  <>
                    {loading ? (
                      <div
                        className="user__header"
                        style={{
                          minHeight: "50px",
                          maxHeight: "50px",
                          minWidth: "220px",
                          maxWidth: "220px",
                          padding: 0,
                          boxShadow: "0px 0px 5px 1px #b5f231",
                        }}
                      >
                        <div className="user__block"></div>
                      </div>
                    ) : (
                      <header
                        className="user__header"
                        style={{
                          minWidth: "220px",
                          maxWidth: "220px",
                          padding: "9px 12px",
                          height: "unset",
                        }}
                      >
                        <div
                          className="user__block"
                          onClick={handleOpenAvatarModal}
                        >
                          <img
                            className="user__avatar"
                            src={
                              userAvatar ||
                              `${baseURL}${fetchedUser?.avatar?.image}`
                            }
                            alt="Avatar"
                            style={{ width: "40px", height: "40px", borderRadius:"10px",     boxShadow: "0px 0px 5px 1px #b5f231", }}
                          />
                          <p
                            className="user__name"
                            style={{ fontSize: "12px" }}
                          >
                            {truncateString(
                              user.username || fetchedUser.username,
                              25
                            )}
                          </p>
                        </div>
                        <div className="balance__block">
                          <img
                            className="balance__icon"
                            src={coin3}
                            alt="Coin icon"
                            style={{ width: "14.42px", height: "14.42px" }}
                          />
                          <p
                            className="balance__amount"
                            style={{ fontSize: "12px" }}
                          >
                            {formatBalance(userBalance)}
                          </p>
                        </div>
                      </header>
                    )}
                  </>
                  {showSettings && (
                    <SettingsModal
                      onClose={handleCloseSettings}
                      onGenderChange={onGenderChange}
                      user={user}
                      tgId={tgId}
                      fetchedUser={fetchedUser}
                      setGenderChanged={setGenderChanged}
                      genderChanged={genderChanged}
                    />
                  )}

                  <button
                    className="settings__button"
                    onClick={handleOpenSettings}
                  >
                    <img
                      className="settings__button_icon"
                      src={settings}
                      alt="Settings"
                    />
                  </button>
                </div>

                <div className="ref__text-block">
                  <h3 className="dailyModal__h">{t("DAILY REWARD")}</h3>
                  <p className="dailyModal__p">{t("Collect coins")}</p>
                </div>
                <div className="daily__rewards_container">
                  {rewardAmounts.map((rewardAmount, index) => {
                    const card = cards?.card_info?.find(
                      (card) => card.id_custom === index + 1
                    );
                    const isCompleted = card?.completed;

                    return (
                      <div
                        key={index}
                        id={`${index + 1}`}
                        className={`daily__reward_item ${
                          isCompleted ? "claimed" : ""
                        }`}
                      >
                        <p className="daily__reward_day">
                          {t("Day")} {index + 1}
                        </p>
                        <img
                          className="daily__reward_coin_icon"
                          src={coin3}
                          alt="Coin"
                        />
                        <p className="daily__reward_amount">{rewardAmount}</p>
                      </div>
                    );
                  })}
                </div>

                {!claimed && cards && cards.can_done === true ? (
                  <button className="reward__add" onClick={handleClaimReward}>
                    {t("Claim")}
                  </button>
                ) : claimed && cards && cards.can_done === false ? (
                  <>
                    <p className="return__tomorrow">
                      {" "}
                      {hours.toString().padStart(2, "0")}:
                      {minutes.toString().padStart(2, "0")}:
                      {seconds.toString().padStart(2, "0")}
                    </p>
                  </>
                ) : (
                  <button
                    className="reward__add"
                    style={{ backgroundColor: "transparent" }}
                  />
                )}
              </>
            )}
            {showReferrals && (
              <Referrals
                onClose={onClose}
                user={user}
                fetchedUser={fetchedUser}
                tgId={tgId}
                baseURL={baseURL}
                userAvatar={userAvatar}
                setBonusesModalOpen={setBonusesModalOpen}
                isBonusesModalOpen={isBonusesModalOpen}
                loading={loading}
                handleOpenAvatarModal={handleOpenAvatarModal}
                truncateString={truncateString}
                formatBalance={formatBalance}
                userBalance={userBalance}
                showSettings={showSettings}
                handleCloseSettings={handleCloseSettings}
                onGenderChange={onGenderChange}
                setGenderChanged={setGenderChanged}
                genderChanged={genderChanged}
                handleOpenSettings={handleOpenSettings}
                settings={settings}
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
              />
            )}
            {showTasks && (
              <TaskList
                onClose={onClose}
                user={user}
                fetchedUser={fetchedUser}
                tgId={tgId}
                baseURL={baseURL}
                userAvatar={userAvatar}
                setBonusesModalOpen={setBonusesModalOpen}
                isBonusesModalOpen={isBonusesModalOpen}
                loading={loading}
                handleOpenAvatarModal={handleOpenAvatarModal}
                truncateString={truncateString}
                formatBalance={formatBalance}
                userBalance={userBalance}
                showSettings={showSettings}
                handleCloseSettings={handleCloseSettings}
                onGenderChange={onGenderChange}
                setGenderChanged={setGenderChanged}
                genderChanged={genderChanged}
                handleOpenSettings={handleOpenSettings}
                settings={settings}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                refreshUser={refreshUser}
                setRefreshUser={setRefreshUser}
              />
            )}
            <div className="dailyModal__navBar">
              <button
                className="dailyModal__navBar_button tasks"
                onClick={handleOpenTasks}
              >
                <p
                  className="dailyModal__navBar_button_p tasks_p"
                  style={{
                    marginLeft: "3px",
                    width: "80px",
                    marginRight: "10px",
                    ...getButtonStyle(showTasks),
                  }}
                >
                  {t("TASKS")}
                </p>
              </button>
              <button
                className="dailyModal__navBar_button friends"
                style={{
                  borderLeft: "1px solid rgba(164, 164, 164, 1)",
                  borderRight: "1px solid rgba(164, 164, 164, 1)",
                }}
                onClick={handleOpenReferrals}
              >
                <p
                  className="dailyModal__navBar_button_p friends_p"
                  style={{
                    marginLeft: "15px",
                    width: "75px",
                    marginRight: "15px",
                    ...getButtonStyle(showReferrals),
                  }}
                >
                  {t("REFERRALS")}
                </p>
              </button>
              <button
                className="dailyModal__navBar_button reward"
                style={{ padding: 0 }}
                onClick={handleOpenDaily}
              >
                <p
                  className="dailyModal__navBar_button_p"
                  style={{ width: "80px", ...getButtonStyle(showDaily) }}
                >
                  {t("DAILY REWARD")}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showSettings && (
        <SettingsModal
          onClose={handleCloseSettings}
          onGenderChange={onGenderChange}
          user={user}
          tgId={tgId}
          fetchedUser={fetchedUser}
          setGenderChanged={setGenderChanged}
          genderChanged={genderChanged}
        />
      )}
      {showAvatarModal && (
        <AvatarModal
          onClose={handleCloseAvatarModal}
          onAvatarSelect={onAvatarSelect}
          avatarSelect={avatarSelect}
          user={user}
          tgId={tgId}
        />
      )}
    </div>
  );
};

export default DailyModal;
