import React, { useState, useEffect } from "react";
import pay2 from "../../../images/pay2.svg";
import coin3 from "../../../images/coin3.svg";
import { useTranslation } from "react-i18next"; // Импорт хука useTranslation

// Импорт изображений модальных окон для разных элементов
import AppleWatchModal from "../../../images/appleModal.jpeg";
import AppleWatchUltraModal from "../../../images/appleWatchUltaModal.png";
import RolexWhiteModal from "../../../images/RolexWhiteModal.png";
import RolexGoldModal from "../../../images/RolexGoldModal.png";
import CartierBrModal from "../../../images/cartierBrModal.png";
import oldSchoolBrModal from "../../../images/oldBrModal.png";
import tiffanyBrModal from "../../../images/tiffanyBrModal.png";
import VanCleefBrModal from "../../../images/vanBrModal.png";
import TiffanyRingModal from "../../../images/tiffanyRingModal.png";
import oldSchoolRingModal from "../../../images/oldRingModal.png";
import cartierWhiteModal from "../../../images/cartierWhiteModal.png";
import cartierYellowModal from "../../../images/cartierGoldModal.png";

const modalImages = {
  1: oldSchoolRingModal,
  2: TiffanyRingModal,
  3: cartierYellowModal,
  4: cartierWhiteModal,
  5: oldSchoolBrModal,
  6: VanCleefBrModal,
  7: CartierBrModal,
  8: tiffanyBrModal,
  9: AppleWatchModal,
  10: AppleWatchUltraModal,
  11: RolexGoldModal,
  12: RolexWhiteModal,
};

const ShopModal = ({ item, getBackgroundClass }) => {
  const { t } = useTranslation(); // Инициализация хука useTranslation
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = modalImages[item.id] || item.image;
    img.onload = () => setIsImageLoaded(true);
    img.onerror = () => setIsImageLoaded(false);
  }, [item.id, item.image]);

  const modalImage = modalImages[item.id] || item.image;
  const additionalClass = modalImage === AppleWatchModal ? "appleWatch" : "";

  return (
    <div className="modal__block_sex">
      <div className="shop__item_block">
        <div className={`shop__item_pic-back ${getBackgroundClass(item.type)} `}>
          {isImageLoaded ? (
            <img className={`shop__item_pic ${additionalClass}`} src={modalImage} alt={item.name} />
          ) : (
            <div className="loading-spinner_modal"></div>
          )}
        </div>
        <h3 className="shop__item_title">{item.name}</h3>
        <p className="shop__item_aboutP">{t(`items.${item.id}.description`)}</p> 
      </div>
      <button className="play__button" style={{ backgroundColor: "rgb(204 204 204)"}}>
        <p className="play__button_p">{t("Soon..")}</p> 
        <img className="play__button_icon" src={pay2} alt="pay icon" />
      </button>
    </div>
  );
};

export default ShopModal;
