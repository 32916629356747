import { useState, useEffect } from "react";

const useCountdownTimer = (initialHours, initialMinutes, initialSeconds) => {
  const [time, setTime] = useState({
    hours: initialHours,
    minutes: initialMinutes,
    seconds: initialSeconds,
  });

  useEffect(() => {
    setTime({
      hours: initialHours,
      minutes: initialMinutes,
      seconds: initialSeconds,
    });

    const countdown = setInterval(() => {
      setTime((prevTime) => {
        const { hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          return { hours, minutes, seconds: seconds - 1 };
        } else if (minutes > 0) {
          return { hours, minutes: minutes - 1, seconds: 59 };
        } else if (hours > 0) {
          return { hours: hours - 1, minutes: 59, seconds: 59 };
        } else {
          clearInterval(countdown);
          return { hours: 0, minutes: 0, seconds: 0 };
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [initialHours, initialMinutes, initialSeconds]);

  return time;
};

export default useCountdownTimer;
