import { useState } from 'react';

const useGetRef = ({ tg_id }) => {
  const [refCode, setRefCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userId = tg_id || localStorage.getItem('tg_id');

  const fetchRefCode = async () => {
    setLoading(true);
    try {
      const initData = window.Telegram.WebApp.initData;

      const pairs = initData.split('&').map(pair => pair.split('='));
      pairs.sort((a, b) => a[0].localeCompare(b[0]));
      const data_check_string = pairs.map(pair => pair.join('=')).join('\n');
      const hash_from_webapp = pairs.find(pair => pair[0] === 'hash')[1];

      const encodedDataCheckString = encodeURIComponent(data_check_string);

      const headers = {
        'Content-Type': 'application/json',
        'data-check-string': initData,
      };

      const response = await fetch(`https://tg-back.itc-hub.ru/api/v1/refcode?tg_id=${userId}`, {
        headers: headers,
      });

      if (response.status === 200) {
        const data = await response.json();
        setRefCode(data.code);
      } else if (response.status === 404) {
        setError(new Error('No referral codes'));
      } else {
        throw new Error('Network response was not ok ' + response.statusText);
      }
    } catch (error) {
      setError(error);
      console.error('Error fetching referral code:', error);
    } finally {
      setLoading(false);
    }
  };

  return { refCode, loading, error, userId, fetchRefCode };
};

export default useGetRef;
