import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import backButton1 from "../../../images/backButton1.svg";
import coin3 from "../../../images/coin3.svg";

const BonusesModal = ({ onClose }) => {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal__overlay_settings" onClick={onClose}>
      <div className="modal__content_settings" onClick={handleClick}>
        <div
          className="modal__block_settings"
          style={{ gap: "10px", width: "310px",          paddingTop: "20px",    
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px" }}
        >
            <div className="fixed__bonuses">
          <button className="backButton_bonuses" onClick={onClose}>
            <img
              className="backButton_settings_icon"
              src={backButton1}
              alt={t("Back")}
            />
          </button>
          <h3 className="bonuses__h">{t("VARIOUS BONUSES")}</h3>
          </div>
          
          <div className="bonuses__tables">
            <div className="bonuses__table" style={{    marginTop: "43px"}}>
              <h4 className="bonuses__table_h">{t("Common")}</h4>
              <div className="bonuses__for">
                <p className="for">{t("For you")}</p>
                <p className="for">{t("For friend")}</p>
              </div>

              <div className="bonuses__row" >
                <div className="bonuses__tier">{t("Common")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+5000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+2500</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row" style={{    padding: "7px 7px"
}}>
                <div className="bonuses__tier">{t("Telegram Premium")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+7500</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+3750</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("Influencer")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+10000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+5000</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("VIP")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+12500</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+6250</span>
                  </div>
                </div>
              </div>

            </div>

            <div className="bonuses__table">
              <h4 className="bonuses__table_h">{t("Telegram Premium")}</h4>
              <div className="bonuses__for">
                <p className="for">{t("For you")}</p>
                <p className="for">{t("For friend")}</p>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("Common")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+10000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+5000</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row" style={{    padding: "7px 7px"
}}>
                <div className="bonuses__tier">{t("Telegram Premium")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+15000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+7500</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("Influencer")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+20000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+10000</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("VIP")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+2500</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+12500</span>
                  </div>
                </div>
              </div>

            </div>


            <div className="bonuses__table">
              <h4 className="bonuses__table_h">{t("Influencer")}</h4>
              <div className="bonuses__for">
                <p className="for">{t("For you")}</p>
                <p className="for">{t("For friend")}</p>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("Common")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+15000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+7500</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row" style={{    padding: "7px 7px"
}}>
                <div className="bonuses__tier">{t("Telegram Premium")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+22500</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+11250</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("Influencer")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+30000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+15000</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("VIP")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+37500</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+18750</span>
                  </div>
                </div>
              </div>

            </div>


            <div className="bonuses__table"  style={{    marginBottom: "20px"}}>
              <h4 className="bonuses__table_h">{t("VIP")}</h4>
              <div className="bonuses__for">
                <p className="for">{t("For you")}</p>
                <p className="for">{t("For friend")}</p>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("Common")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+20000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+10000</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row" style={{    padding: "7px 7px"
}}>
                <div className="bonuses__tier">{t("Telegram Premium")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+30000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+15000</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("Influencer")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+40000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+20000</span>
                  </div>
                </div>
              </div>

              <div className="bonuses__row">
                <div className="bonuses__tier">{t("VIP")}</div>
                <div className="bonuses__box">
                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+50000</span>
                  </div>

                  <div className="bonuses__cell">
                    <img className="coin__icon" src={coin3} alt="coin" />
                    <span className="price_bonus">+25000</span>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusesModal;
